import { ListGroup } from "flowbite-react";
import moment from "moment";
import { Request } from "../../types/request";
import { services } from "../../constants/services";
import { useNavigate } from "react-router-dom";
import { SquareSkeleton } from "../../components/skeleton";
import ThreeDotLoader from "../../components/loader/threedotLoader";

export default function RecentRequests({
  total,
  tickets,
  isLoading,
  isFetching
}: {
  total: number;
  tickets: Request[];
  isLoading: boolean;
  isFetching: boolean
}) {
  const navigate = useNavigate();
  return (
    <div className="bg-neutral-50 border dark:bg-primary-950 dark:border-primary-900 flex flex-col h-full border-neutral-100 px-5 py-3 gap-3 rounded-2xl shadow-sm lg:max-w-[50%] w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title flex items-center gap-2 capitalize font-medium dark:text-white">new requests {isFetching ?
          <div className="flex items-center gap-1 text-xs lowercase text-neutral-700">
            (   updating
            <ThreeDotLoader />)
          </div> : ''}</p>
        <p className="w-fit h-auto text-neutral-500 dark:text-neutral-200 text-sm">{total} items</p>
      </div>
      <div className="relative max-h-max h-full min-h-[25vh] overflow-x-auto">
        {isLoading ? (
          <ListGroup className="w-full max-h-max min-w-full overflow-auto">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
              <ListGroup.Item>
                <div className="grid w-full items-center grid-cols-4 space-x-2">
                  <SquareSkeleton className=" col-span-2 h-5" />
                  <SquareSkeleton className=" col-span-1 h-5" />
                  <SquareSkeleton className=" col-span-1 h-5" />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : tickets.length > 0 ? (
          <ListGroup className="w-full max-h-max min-w-full overflow-auto">
            {tickets.map((item) => {
              return (
                <ListGroup.Item
                  key={item._id}
                  onClick={() => {
                    navigate("/requests");
                  }}
                  className="w-full"
                >
                  <div className="grid w-full items-center grid-cols-4 space-x-2">
                    <div className="flex col-span-2 flex-nowrap items-center gap-2">
                      {
                        services.find(
                          (service) => service.name === item.service
                        )?.icon
                      }
                      <p className="text-neutral-900 dark:text-white text-left text-sm text-normal">
                        {item.service}
                      </p>
                    </div>
                    <p className="text-neutral-900 dark:text-white text-nowrap text-left text-sm font-normal">
                      {item.patient.userName}
                    </p>
                    <p className="text-neutral-900 dark:text-white text-xs text-nowrap text-center font-light">
                      {moment(item.createdAt).format("MMM DD, hh:mm")}
                    </p>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <p className="empty-msg">
            no recent requests!
          </p>
        )}
      </div>
    </div>
  );
}
