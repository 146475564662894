import { produce } from "immer";
import { create } from "zustand";
import { Message } from "../types/message";

interface ChatStore {
  chats: Record<
    string,
    {
      messages: Record<string, Message>;
      ids: string[];
      lastPageSize: number;
      lastPage?: number;
      nextPage?: number;
      newMessages: number;
      haveMoreMessage: boolean;
      newSendMessage?: string;
    }
  >;
  updateChat(
    chatId: string,
    messages: Record<string, Message>,
    ids: string[],
    page: number,
    haveMoreMessage: boolean,
    push?: boolean
  ): void;
  addMessage(chatId: string, message: Message): void;
  updateMessage(
    chatId: string,
    messageId: string,
    message: Partial<Message>
  ): void;
  resetNewMessages(chatId: string): void;
}
export const useChatStore = create<ChatStore>((set, get) => ({
  chats: {},
  updateChat(chatId, messages, ids, page, haveMoreMessage, push = false) {
    set(
      produce((state: ChatStore) => {
        if (!(chatId in state.chats)) {
          state.chats[chatId] = {
            messages,
            ids,
            lastPage: 2,
            nextPage: undefined,
            lastPageSize: 0,
            newMessages: 0,
            haveMoreMessage,
          };
        } else {
          const chat = state.chats[chatId];
          const serverSideIds = Object.values(chat.messages).map(
            (item) => item._id
          );
          let newIds = ids.filter((id) => !serverSideIds.includes(id));
          chat.messages = { ...chat.messages, ...messages };
          if (push) {
            chat.ids = [...chat.ids, ...newIds];
            chat.newMessages = newIds.length;
          } else {
            chat.ids = [...newIds, ...chat.ids];
            chat.lastPageSize = ids.length;
            chat.lastPage = page;
            state.chats[chatId].haveMoreMessage = haveMoreMessage;
          }
        }
      })
    );
  },
  addMessage(chatId, message) {
    set(
      produce((state: ChatStore) => {
        if (chatId in state.chats) {
          state.chats[chatId].messages[message._id] = message;
          state.chats[chatId].ids.push(message._id);
          state.chats[chatId].newSendMessage = message.localId;
          // state.chats[chatId].length++;
        }
      })
    );
  },
  updateMessage(chatId, messageId, message) {
    set(
      produce((state: ChatStore) => {
        if (chatId in state.chats) {
          if (messageId in state.chats[chatId].messages) {
            Object.assign(state.chats[chatId].messages[messageId], message);
          }
        }
      })
    );
  },
  resetNewMessages(chatId) {
    set(produce((state: ChatStore) => (state.chats[chatId].newMessages = 0)));
  },

}));



export const clearChatStore = () => useChatStore.setState(produce((state: ChatStore) => {
  state.chats = {}
}))