import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../stores/auth-store";
import { FollowupsStatus } from "../types/followup";
import apiCaller from "../utils/api-caller";
import { DEFAULT_CACHE_CONFIG, DEFAULT_PULL } from "../constants/api";
import { useEffect } from "react";
import { produce } from 'immer'
import { AppStore, useAppStore } from "../stores/app-store";
import { StatusCodes } from "http-status-codes";


export default function useFollowupsBackgroundFetch() {
    const calender = useAuthStore((state) => state.user?.calender);
    const resetCalender = useAuthStore((state) => state.resetCalender);
    const status = useQuery({
        queryKey: ["followups", "status"],
        queryFn: () =>
            apiCaller<FollowupsStatus>({
                method: "GET",
                url: "/followups/status",
                errorHandler(error) {
                    if (error.status === StatusCodes.INTERNAL_SERVER_ERROR) {
                        console.log('internal server erro')
                        // resetCalender(); // FIXME
                    }
                },
            }),
        enabled: !!calender?.id,
        ...DEFAULT_PULL,
    });


    useEffect(() => {
        useAppStore.setState(produce((state: AppStore) => {
            state.followupsStatus.error = status.error;
            state.followupsStatus.isError = status.isError;
            state.followupsStatus.isLoading = status.isLoading;
            state.followupsStatus.isFetching = status.isFetching;
            state.followupsStatus.item = status.data as FollowupsStatus
        }))
    }, [status])
}