import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, FileInput, Label, FloatingLabel, Modal } from "flowbite-react";
import { useState } from "react";
import PatientSelect from "../../../components/PatientSelect";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import * as yup from 'yup';
import * as axios from 'axios'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Patient } from "../../../types/patient";
const schema = yup.object().shape({
  summary: yup.string().required(),
  description: yup.string(),
  patient: yup.object().required(),
  date: yup.date().required(),
})

export default function AddEventModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { register, reset, control, handleSubmit, formState: { isValidating } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date()
    }
  })
  const [files, setFiles] = useState<File[]>([]);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files));
    }
  };

  const queryClient = useQueryClient();
  const addEventMutation = useMutation({
    mutationFn: ({ summary, description, date, patient }: yup.InferType<typeof schema>) => {
      const data = {
        summary,
        description,
        start: {
          dateTime: new Date(date).toISOString(),
          timeZone: calender?.timeZone,
        },
        end: {
          dateTime: new Date(date).toISOString(),
          timeZone: calender?.timeZone,
        },
      };
      const formData = axios.toFormData(data);
      if (files?.length) formData.append("file", files[0]);
      return apiCaller<void>({
        method: "POST",
        url: `/videochats/${(patient as Patient)._id}`,
        body: formData as FormData,
        formData: true,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["videochats"] });
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      handleCLose();
    },
  });
  const handleCLose = () => {
    reset();
    setFiles([]);
    addEventMutation.reset()
    onClose();
  }
  const calender = useAuthStore((state) => state.user?.calender);
  return (
    <Modal position="center" show={show} onClose={handleCLose}>
      <form onSubmit={handleSubmit((data) => addEventMutation.mutate(data))}>
        <Modal.Header className="modal-header">Create VideoChat</Modal.Header>
        <Modal.Body className="max-h-[60vh] overflow-auto">
          <div className="space-y-3 ">
            <FloatingLabel
              {...register('summary')}
              type="text"
              id="summary"
              variant="outlined"
              label="Title"
              required
            />
            <Controller
              control={control}
              name='patient'
              render={({ field }) => (
                <PatientSelect
                  value={field.value as Patient}
                  onSelect={(patient) => field.onChange(patient)}
                  label="select a patient"

                />
              )}
            />
            <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="Date"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <input
                  {...register('date')}
                  type="datetime-local"
                  id="Date"
                  required
                  className="block w-full dark:bg-transparent dark:text-neutral-100 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                  value="Upload file"
                />
              </div>
              <FileInput
                id="file-upload"
                multiple
                className="bg-transparent"
                onChange={handleFileChange}
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Description
              </label>
              <textarea
                {...register('description')}
                id="description"
                className="mt-1 block w-full bg-transparent rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                rows={3}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className=" border-0 justify-center gap-4">
          <Button
            onClick={handleCLose}
            disabled={addEventMutation.isPending || isValidating}
            color="gray"
            className="modal-btn">Cancel</Button>
          <Button className="modal-btn" type="submit"
            disabled={addEventMutation.isPending || isValidating}
            isProcessing={addEventMutation.isPending || isValidating}
          >
            Add
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
