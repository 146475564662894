import { Tabs } from "flowbite-react";
import PatientRequestsTable, { PatientFollowupsTable } from "./tables";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import { Patient } from "../../../types/patient";

export default function PatientPage() {
  const { patientId } = useParams() as { patientId: string };
  const ownerId = useAuthStore((state) => state.user?._id);
  const patient = useQuery({
    queryKey: ["patients", patientId],
    queryFn: () =>
      apiCaller<Patient>({
        method: "GET",
        url: `/owner/${ownerId}/patients/${patientId}`,
      }),
  });

  //TODO add loading indicator when fetching patient data

  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:px-2 max-sm:pt-2 sm:p-4">
      <p className="title capitalize dark:text-white">{patient.data?.userName}</p>
      <Tabs>
        <Tabs.Item active title="Requests">
          <PatientRequestsTable patientId={patientId} />
        </Tabs.Item>
        <Tabs.Item title="Follow Ups">
          <PatientFollowupsTable patientId={patientId} />
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
