import { Button } from "flowbite-react";
import { ReactNode, useState } from "react";
import { MdFilterAlt } from "react-icons/md";

//EXtremly Bad Component
export function FilterBar({
  children,
  searchLabel,
  searchPlaceHolder = 'Search  ...',
  customStyle,
  onChange,
  onSubmit,
  onCancle
}: {
  children?: ReactNode;
  onChange?: (e: any) => void;
  onCancle?: () => void
  onSubmit?: React.FormEventHandler<HTMLFormElement>
  searchLabel?: string;
  searchPlaceHolder?: string;
  customStyle?: string;
}) {
  const [show, setshow] = useState<boolean>(false)
  return show ?
    <form className={`${customStyle} relative scale-in shadow-sm w-full flex flex-wrap gap-2 bg-primary-50 dark:bg-primary-950 p-4 rounded-md`}
      onSubmit={onSubmit}
    >
      <div className="relative w-full max-w-md ">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">{searchLabel}</label>
        <input
          onChange={onChange}
          name="search"
          type="search"
          id="default-search"
          className="block w-full p-4 text-sm text-gray-900 border bg-[#f9fafb] border-gray-300 rounded-lg min-w-[195px]   focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder={searchPlaceHolder}
        />
      </div>
      {children}

      <div className="flex items-center justify-end gap-3 mt-4 w-full">
        <button
          onClick={() => {
            setshow(false)
            if (onCancle) onCancle()

          }}
          className=" capitalize px-3 py-2 sm:text-sm rounded-lg max-sm:!text-xs text-neutral-700 hover:!bg-neutral-300 hover:shadow-sm bg-neutral-200 dark:bg-primary-900 dark:text-neutral-300"
        >
          cancel
        </button>
        <button
          type="submit"
          className="capitalize px-3 py-2 bg-primary-700 sm:text-sm rounded-lg max-sm:!text-xs text-white hover:shadow-sm">
          filter
        </button>
      </div>
    </form>

    :
    <Button
      className="w-fit flex items-center gap-2 text-sm font-medium capitalize"
      onClick={() => setshow(!show)}>
      <MdFilterAlt className="text-white w-4 h-4" />
    </Button>



}
