import { Button } from "flowbite-react";
import { FaInfoCircle } from "react-icons/fa";
import apiCaller from "../../../utils/api-caller";
import { useMutation } from "@tanstack/react-query";
import { useAuthStore } from "../../../stores/auth-store";
import { User } from "../../../types/user";

export default function RequestAccess() {
  const authState = useAuthStore((state) => state);
  const loginMutation = useMutation({
    mutationFn: () =>
      apiCaller<{ url: string }>({
        method: "GET",
        url: "/auth/google/signup",
        errorHandler(error) {},
      }),
    onSuccess(data) {
      const popup = window.open(data.url, "Google", "width=500,height=600");
      window.addEventListener("message", (event) => {
        if (event.data === "success") {
          authState.setUSer({
            ...(authState.user as User),
            calender: {
              access: true,
            },
          });
          popup?.close();
        }
      });
    },
  });
  return (
    <div className="bg-neutral-50 dark:bg-primary-800 rounded-lg h-fit flex flex-col items-center shadow-lg gap-4 max-w-lg p-6">
      <FaInfoCircle size={35} className="dark:text-neutral-300"/>
      <h2 className=" capitalize text-black dark:text-neutral-100 text-xl max-sm:text-lg font-semibold">
        we need access
      </h2>
      <h2 className=" capitalize dark:text-neutral-300 text-neutral-600 max-sm:text-xs text-center text-sm">
        please give us access to your google calendar or switch to another with
        access account.
      </h2>
      <Button className=" capitalize" onClick={() => loginMutation.mutate()}>get access</Button>
    </div>
  );
}
