export type NotifSeverity = "info" | "error" | "success";
export default function Notif({
  title,
  severity = "info",
  desc,
  image,
  onClose,
}: {
  severity?: NotifSeverity;
  image?: any;
  title: string;
  desc?: string;
  onClose: () => void;
}) {
  const severityColorMapper = {
    info: {
      text: "text-sky-600",
      primary: "border-sky-600",
      secondary: "bg-sky-50",
      thertiary: "bg-sky-800",
    },
    error: {
      text: "text-rose-600",
      primary: "border-rose-600",
      secondary: "bg-rose-50",
      thertiary: "bg-rose-800",
    },
    success: {
      text: "text-primary-600",
      primary: "border-primary-600",
      secondary: "bg-primary-50",
      thertiary: "bg-primary-800",
    },
  } as Record<
    NotifSeverity,
    {
      text: string;
      primary: string;
      secondary: string;
      thertiary: string;
    }
  >;

  return (
    <div
      className={`flex w-full shadow-md rounded-lg xs:max-w-[400px] xs:min-w-[400px] flex-col border
         ${severityColorMapper[severity].primary} 
          ${severityColorMapper[severity].secondary}`}
    >
      <div
        className={`w-full flex justify-between max-sm:p-2 sm:p-4`}
      >
        <p
          className={`text-sm !leading-5 max-xs:text-xs ${severityColorMapper[severity].text} font-semibold capitalize w-full`}
        >
          {title}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          className={`bi cursor-pointer transition-all duration-100 ease-linear hover:scale-110 font-bold bi-x ${severityColorMapper[severity].text}`}
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </div>
    </div>
  );
}
