import { useNavigate } from "react-router-dom";
import { Dropdown, Navbar } from "flowbite-react";
import { HiChartPie, HiInboxIn, HiClipboardList } from "react-icons/hi";
import { MdPeopleAlt } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { IoLogOut } from "react-icons/io5";
import { useAuthStore } from "../stores/auth-store";
import Avatar from "./avatar";
import { IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
export default function Appbar({
  userName,
  active,
  handleActiveTab,
  toggleModal,
}: {
  userName: string;
  active: string;
  handleActiveTab: (e: string) => void;
  toggleModal: (e: boolean) => void;
}) {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const path = window.location.pathname;
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);
  return (
    <Navbar fluid rounded className="appbar bg-neutral-50 shadow-sm">
      <div className="flex max-sm:flex-col justify-between w-full">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <div className="flex text-sm max-sm:justify-between w-full items-center gap-3 capitalize">
              <div className="flex text-sm w-full items-center gap-3 capitalize">
                <button
                  className={`${path === "/" ? "hidden" : "block"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (path !== "/")
                      navigate(-1)
                  }}
                >
                  <BsArrowLeft className="dark:text-white text-neutral-500" />
                </button>
                <Avatar online userName={userName} />
                <div className="flex flex-col  pr-4 items-start gap-[2px]">
                  <p className="font-semibold text-sm text-primary-900 dark:text-primary-300">
                    {userName}
                  </p>
                  <p className="text-neutral-500 text-xs dark:text-neutral-300" style={{textTransform:"none"}}>
                    {user?.email}
                  </p>
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDarkMode();
                }}
                className="bg-neutral-200 sm:hidden gap-2 w-10 h-10 dark:bg-primary-dark-200 rounded-2xl py-2 px-3 flex items-center justify-center "
              >
                {darkMode ? (
                  <IoSunnyOutline className="dark:text-neutral-200" />
                ) : (
                  <IoMoonOutline className="" />
                )}
              </button>
            </div>
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{user?.pharmacy}</span>
            <span className="block truncate text-sm font-medium">
              {user?.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item onClick={() => navigate("/")}>Dashboard</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => toggleModal(true)}>
            Logout
          </Dropdown.Item>
        </Dropdown>
        <div className="flex items-center gap-5">
          <button
            onClick={toggleDarkMode}
            className="bg-neutral-200 max-sm:hidden gap-2 w-10 h-10 dark:bg-primary-dark-200 rounded-2xl py-2 px-3 flex items-center justify-center "
          >
            {darkMode ? (
              <IoSunnyOutline className="dark:text-neutral-200" />
            ) : (
              <IoMoonOutline className="" />
            )}
          </button>
          <Navbar.Toggle className="max-sm:hidden" />
        </div>
      </div>
      <Navbar.Collapse className="max-sm:hidden bg-white shadow-lg absolute top-[3.9rem] dark:bg-primary-950 left-0">
        <div className="md:hidden">
          <Navbar.Link
            active={active === "dashboard"}
            onClick={() => {
              handleActiveTab("dashboard");
              navigate("/");
            }}
          >
            <div className="flex items-center gap-2">
              <HiChartPie />
              Dashboard
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "requests"}
            onClick={() => {
              handleActiveTab("requests");
              navigate("/requests");
            }}
            // href="/requests"
            icon={HiInboxIn}
            labelColor={"blue"}
            label="3"
          >
            <div className="flex items-center gap-2">
              <HiInboxIn />
              Requests
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "patients"}
            onClick={() => {
              handleActiveTab("patients");
              navigate("/patients");
            }}
            // href="/patients"
            icon={MdPeopleAlt}
          >
            <div className="flex items-center gap-2">
              <MdPeopleAlt />
              Patients
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "followups"}
            onClick={() => {
              handleActiveTab("followups");
              navigate("/followups");
            }}
            // href="/followups"
            icon={HiClipboardList}
          >
            <div className="flex items-center gap-2">
              <HiClipboardList />
              FollowUps
            </div>
          </Navbar.Link>
          <Navbar.Link onClick={() => toggleModal(true)} icon={IoLogOut}>
            <div className="flex items-center gap-2 text-red-600 dark:text-red-500">
              <IoLogOut className="fill-red-600 dark:fill-red-500" />
              Logout
            </div>
          </Navbar.Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}
