import { useState, useRef, useEffect } from "react";
import { provinces } from "../../constants/canadaProvince";
import { on } from "events";

interface Province {
  name: string;
  cities: string[];
}

export default function LocationInput({
  value,
  onChange,
  error

}: {
  value: {
    province: string;
    city: string;
  };
  error?: boolean
  onChange?: (province: string, city: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState<Province>()
  const ref = useRef<HTMLDivElement>(null);
  const filteredProvinces = provinces.filter((province) =>
    province.name.toLowerCase().includes(value.province.toLowerCase())
  );
  const filteredCities = (selectedProvince?.cities || []).filter(city =>
    city.toLowerCase().includes(value.city.toLowerCase())
  )

  const handleProvinceSelect = (province: Province) => {
    setSelectedProvince(province)
    if (onChange)
      onChange(province.name, '');
    setOpen(false); // Close the dropdown after selecting a province
  };

  const handleCitySelect = (city: string) => {
    if (onChange) {
      onChange(selectedProvince?.name as string, city);
    }
    setShow(false); // Close the dropdown after selecting a city
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-black mb-2 w-full" ref={ref}>
      <div className="flex">
        <input
          type="text"
          name="province"
          className={`${error?"border-red-500 text-red-600 placeholder:text-red-600":" dark:border-[#4b5563] border-neutral-300 dark:text-white"} border dark:bg-transparent  rounded-l-lg text-sm p-3 pl-7 w-full`}
          placeholder="province"
          value={value.province}
          onClick={() => setOpen(!open)}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value, value.city || '')
            }
          }}
          autoComplete='off'
        />
        <input
          type="text"
          name="city"
          className={`${error?"border-red-500 text-red-600 placeholder:text-red-600":" dark:border-[#4b5563] border-neutral-300 dark:text-white"}} border dark:bg-transparent rounded-r-lg text-sm p-3 w-full`}
          placeholder="city"
          value={value.city}
          onClick={() => setShow(!open)}
          onChange={(e) => {
            if (onChange) {
              onChange(value.province || '', e.target.value)
            }
          }}
          autoComplete='off'

        />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="currentColor"
        className={`bi bi-geo-alt-fill text-gray-500 absolute top-[15px] left-2`}
        viewBox="0 0 16 16"
      >
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
      </svg>
      {open && (
        <div className=" absolute left-0 z-[100] max-h-[20vh] overflow-auto bg-[#fafafa] border rounded shadow-md mt-1 xs:w-[300px]">
          {filteredProvinces.map((province) => (
            <div
              key={province.name}
              className="p-2 max-sm:text-xs sm:text-sm hover:bg-primary-50 rounded-md cursor-pointer"
              onClick={() => handleProvinceSelect(province)}
            >
              {province.name}
            </div>
          ))}
        </div>
      )}
      {show && selectedProvince && (
        <div className=" absolute right-0 z-[100] max-h-[20vh] overflow-auto bg-[#fafafa] border rounded shadow-md mt-1 xs:w-[300px]">
          {filteredCities.map((city) => (
            <div
              key={city}
              className="p-2 text-black max-sm:text-xs sm:text-sm hover:bg-primary-50 rounded-md cursor-pointer"
              onClick={() => handleCitySelect(city)}
            >
              {city}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
