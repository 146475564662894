import { Sidebar } from "flowbite-react";
import { HiChartPie, HiInboxIn, HiClipboardList } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5";
import getRequestsStatusLable from "./utils/get-requests-status-lable";
import SideBarItem from "./components/SidebarItem";
import { useAppStore } from "../../stores/app-store";
import Logo from "../../assets/images/logo.png"
import { MdPeopleAlt } from "react-icons/md";
import { useShallow } from "zustand/shallow";
import { MdVideoChat } from "react-icons/md";


export default function SideBar({
  active,
  handleActiveTab,
  toggleModal,
}: {
  active: string;
  handleActiveTab: (e: string) => void;
  toggleModal: (e: boolean) => void;
}) {

  const requestsStatus = useAppStore(
    useShallow((state) => state.requestsStatus)
  );

  return (
    <Sidebar
      collapseBehavior="collapse"
      aria-label="Sidebar with multi-level dropdown example"
      className="sidebar h-full max-md:hidden min-h-full w-full max-md:max-w-56 md:max-w-60 bg-white dark:bg-primary-dark-50"
    >
      <a href="https://pharmacanada.ca/" className="flex flex-nowrap mt-4 mb-10 px-4">
        <img src={Logo} className="mr-1 h-7 sm:h-9" alt="Flowbite React Logo" />
        <span className="self-center text-neutral-800 max-xs:hidden whitespace-nowrap max-sm:text-sm sm:text-lg font-semibold dark:text-white">
          Pharma Canada
        </span>
      </a>
      <Sidebar.Items className="md:px-4">
        <Sidebar.ItemGroup>
          <SideBarItem
            active={active === "dashboard"}
            onClick={() => handleActiveTab("dashboard")}
            href="/"
            icon={() => <HiChartPie className={`w-5 h-5 ${active === "dashboard" ? "fill-neutral-700 dark:fill-white" : "fill-neutral-500 dark:fill-neutral-200"}`} />}
          >
            <p className={`capitalize ${active === "dashboard" ? "font-semibold dark:text-white text-neutral-800" : "font-normal dark:text-neutral-200 text-neutral-500"} text-sm`}>
              Dashboard
            </p>
          </SideBarItem>

          <SideBarItem
            active={active === "requests"}
            onClick={() => handleActiveTab("requests")}
            href="/requests"
            icon={() => <HiInboxIn className={`w-5 h-5 min-w-5 min-h-5  ${active === "requests" ? "fill-neutral-700 dark:fill-white" : "fill-neutral-500 dark:fill-neutral-200"}`} />}
            labelColor={"blue"}
            label={getRequestsStatusLable(requestsStatus.item || {})}
          >
            <p className={`capitalize ${active === "requests" ? "font-semibold dark:text-white text-neutral-800" : "font-normal dark:text-neutral-200 text-neutral-500"} text-sm`}>
              Requests
            </p>
          </SideBarItem>
          <SideBarItem
            active={active === "patients"}
            onClick={() => handleActiveTab("patients")}
            href="/patients"
            icon={() => <MdPeopleAlt className={`w-5 h-5 ${active === "patients" ? "fill-neutral-700 dark:fill-white" : "fill-neutral-500 dark:fill-neutral-200"}`} />}
          >
            <p className={`capitalize ${active === "patients" ? "font-semibold dark:text-white text-neutral-800" : "font-normal dark:text-neutral-200 text-neutral-500"} text-sm`}>
              Patients
            </p>
          </SideBarItem>
          <SideBarItem
            active={active === "followups"}
            onClick={() => handleActiveTab("followups")}
            href="/followups"
            icon={() => <HiClipboardList className={`w-5 h-5 ${active === "followups" ? "fill-neutral-700 dark:fill-white" : "fill-neutral-500 dark:fill-neutral-200"}`} />}
          >
            <p className={`capitalize ${active === "followups" ? "font-semibold dark:text-white text-neutral-800" : "font-normal dark:text-neutral-200 text-neutral-500"} text-sm`}>
              FollowUps
            </p>
          </SideBarItem>
          <SideBarItem
            active={active === "video-chats"}
            onClick={() => handleActiveTab("video-chats")}
            href="/video-chats"
            icon={() => <MdVideoChat className={`w-5 h-5 ${active === "video-chats" ? "fill-neutral-700 dark:fill-white" : "fill-neutral-500 dark:fill-neutral-200"}`} />}
          >
            <p className={`capitalize ${active === "video-chats" ? "font-semibold dark:text-white text-neutral-800" : "font-normal dark:text-neutral-200 text-neutral-500"} text-sm`}>
              Video Chats
            </p>
          </SideBarItem>
          <SideBarItem
            active={active === "logout"}
            onClick={() => {
              toggleModal(true);
              handleActiveTab("logout");
            }}
            href="#"
            icon={() => <IoLogOut className="fill-red-600 dark:fill-red-500 h-5 w-5" />}
          >
            <p className="text-red-600 dark:text-red-500 text-sm font-normal capitalize">
              Logout
            </p>
          </SideBarItem>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
