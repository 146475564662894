import { HiChartPie, HiInboxIn, HiClipboardList } from "react-icons/hi";
import { MdVideoChat } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MdPeopleAlt } from "react-icons/md";

export default function NavBar({
  active,
  handleActiveTab,
}: {
  active: string;
  handleActiveTab: (e: string) => void;
}) {
  const navigate = useNavigate();
  return (
    <div className="sticky sm:hidden z-50 w-full h-14 bg-white border border-neutral-200 bottom-0 left-1/2 dark:bg-primary-800 dark:border-neutral-600">
      <div className="grid h-full grid-cols-4 mx-auto">
        <button
          onClick={() => {
            handleActiveTab("dashboard");
            navigate("/");
          }}
          data-tooltip-target="tooltip-home"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear  ${active === "dashboard" && "bg-neutral-100 border-primary-600"} hover:bg-neutral-100 hover:border-primary-600 border-b-[3px] group`}
        >
          <HiChartPie
            className={`w-6 h-6 mb-1 text-neutral-400 group-hover:text-primary-700  
              ${active === "dashboard" && "text-primary-700"}`}
          />
          <span className="sr-only">dashboard</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("requests");
            navigate("/requests");
          }}
          data-tooltip-target="tooltip-wallet"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${active === "requests" && "bg-neutral-100 border-primary-600"
            } hover:bg-neutral-100 hover:border-primary-600 border-b-[3px] group`}
        >
          <HiInboxIn
            className={`w-6 h-6 mb-1 text-neutral-500 group-hover:text-primary-700 ${active === "requests" && "text-primary-700"
              }`}
          />
          <span className="sr-only">requests</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("patients");
            navigate("/patients");
          }}
          data-tooltip-target="tooltip-wallet"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${active === "patients" && "bg-neutral-100 border-primary-600"
            } hover:bg-neutral-100 hover:border-primary-600 border-b-[3px] group`}
        >
          <MdPeopleAlt
            className={`w-6 h-6 mb-1 text-neutral-500 group-hover:text-primary-700 ${active === "patients" && "text-primary-700"
              }`}
          />
          <span className="sr-only">Patients</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("followups");
            navigate("/followups");
          }}
          data-tooltip-target="tooltip-settings"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${active === "followups" && "bg-neutral-100 border-primary-600"
            } hover:bg-neutral-100 hover:border-primary-600 border-b-[3px] group`}
        >
          <HiClipboardList
            className={`w-6 h-6 mb-1 text-neutral-500 group-hover:text-primary-700 ${active === "followups" && "text-primary-700"
              }`}
          />
          <span className="sr-only">followups</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("video-chat");
            navigate("/video-chats");
          }}
          data-tooltip-target="tooltip-settings"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${active === "video-chat" && "bg-neutral-100 border-primary-600"
            } hover:bg-neutral-100 hover:border-primary-600 border-b-[3px] group`}
        >
          <MdVideoChat
            className={`w-6 h-6 mb-1 text-neutral-500 group-hover:text-primary-700 ${active === "video-chat" && "text-primary-700"
              }`}
          />
          <span className="sr-only">video chats</span>
        </button>
      </div>
    </div>
  );
}
