import { MdDelete } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
export default function Reminder({ reminders, add, remove, update }: {
    add: () => void,
    remove: (index: number) => void,
    update: (index: number, data: { minutes: number; method: "email" | "popup" }) => void
    reminders: { minutes: number; method: "email" | "popup", id: any }[],
}) {
    return (
        <div className="flex w-full gap-4">
            <div className="w-full">
                <div className="mb-2 flex w-full items-center justify-between">
                    <label className="block text-sm font-medium text-gray-700">
                        Reminders
                    </label>
                    <button
                        type="button"
                        onClick={add}
                        className="inline-flex items-center text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        <IoAddCircle className="text-lg" />
                    </button>
                </div>
                <div className="w-full flex flex-col gap-2">
                    {reminders.map((reminder, index) => (
                        <div key={index} className="flex w-full max-sm:flex-col max-sm:gap-2 sm:items-center sm:space-x-3">
                            <select
                                value={reminder.minutes}
                                onChange={(e) => {
                                    update(index, {
                                        method: reminder.method,
                                        minutes: e.target.value as any
                                    });
                                }}
                                required
                                className="rounded-md dark:bg-transparent dark:text-white border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                            >
                                <option className="text-black" value={0}>At time of event</option>
                                <option className="text-black" value={5}>5 minutes before</option>
                                <option className="text-black" value={10}>10 minutes before</option>
                                <option className="text-black" value={30}>30 minutes before</option>
                                <option className="text-black" value={60}>1 hour before</option>
                                <option className="text-black" value={1440}>1 day before</option>
                            </select>
                            <div className="flex items-center w-full gap-2">
                                <select
                                    value={reminder.method}
                                    onChange={(e) => {
                                        update(index, {
                                            method: e.target.value as any,
                                            minutes: reminder.minutes
                                        });
                                    }}
                                    required
                                    className="rounded-md dark:bg-transparent dark:text-white w-full border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                                >
                                    <option className="text-black" value="popup">Notification</option>
                                    <option className="text-black" value="email">Email</option>
                                </select>
                                <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="text-red-600 hover:text-red-700"
                                >
                                    <MdDelete className="text-lg" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}