import { Button } from "flowbite-react";
import EmbeddedGoogleCalender from "../../components/GoogleCalender";
import CalenderList from "../../components/GoogleCalender/CalenderList";
import RequestAccess from "./components/RequestAccess";
import AddEventModal from "./components/AddEventModal";
import FollowupsTable from "./components/FollowupsTable";
import { useState } from "react";
import { useAuthStore } from "../../stores/auth-store";
import Header from "../../components/header";

export function Followups() {
  const [modal, setModal] = useState({
    add: false,
  });
  const [tab, setTab] = useState<"calendar" | "table">("calendar")
  const calender = useAuthStore((state) => state.user?.calender);
  return calender?.access ? (
    <div className="w-full h-full max-md:px-4 flex flex-col">
      <Header title="followups">
        <Button
          className="add-btn"
          onClick={() => setModal((prev) => ({ ...prev, add: true }))}
        >
          <p className="w-full text-sm max-sm:text-xs font-medium capitalize">
            Add followup
          </p>
        </Button>
      </Header>
      <div className="w-full h-full min-h-[calc(100vh_-_142px)] max-h-[calc(100vh_-_142px)] max-sm:max-h-[calc(100vh_-_175px)] max-sm:min-h-[calc(100vh_-_175px)] flex flex-col">
        <div className="w-full flex gap-3 border-b border-neutral-100 dark:border-neutral-700 items-center my-3">
          <button
            onClick={() => setTab("calendar")}
            className={`${tab === "calendar" ? "border-b-2 border-primary-500 dark:text-primary-500 text-primary-700" : "text-neutral-500 dark:text-neutral-100 border-b-2 border-transparent"} transition-all ease-linear duration-100 p-2 capitalize text-sm `}>calendar</button>
          <button
            onClick={() => setTab("table")}
            className={`${tab === "table" ? "border-b-2 border-primary-500 dark:text-primary-500 text-primary-700" : "text-neutral-500 dark:text-neutral-100 border-b-2 border-transparent"} transition-all ease-linear duration-100 p-2 capitalize text-sm `}>table</button>
        </div>
        <div className={`${tab === "calendar" ? "block" : "hidden"} flex flex-col gap-2`}>
          <CalenderList />
          <EmbeddedGoogleCalender calenderId={calender.id} />
        </div>
        {tab === "table" &&
          <FollowupsTable />
        }
      </div>
      <AddEventModal
        show={modal.add}
        onClose={() => setModal((prev) => ({ ...prev, add: false }))}
      />
    </div>
  ) : (
    <div className="h-full min-h-[calc(100vh_-_100px)] px-4 max-h-[calc(100vh_-_100px)] max-sm:max-h-[calc(100vh_-_130px)] max-sm:min-h-[calc(100vh_-_130px)] flex items-center justify-center w-full">
      <RequestAccess />
    </div>
  );
}
