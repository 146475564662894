import { FilterBar } from "../../../components/filters/filterbar";
import DateFilter from "../../../components/filters/date-filter";
import { Select } from "flowbite-react";
import { RequestState } from "../../../types/request-status";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";


export type RequestsQuery = {
    state?: RequestState | "all" | "tickets";
    name?: string;
    from?: Date | null;
    to?: Date | null;
}

type FilterProps = {
    onSearch?: (search: string) => void
    onFilter?: (query: RequestsQuery) => void
}
export default function Filter(props: FilterProps) {
    const [query, setQuery] = useState<{
        state?: RequestState | "all" | "tickets";
        name?: string;
        from?: Date | null;
        to?: Date | null;
    }>({
        from: null,
        to: null,
    });
    const onSearch = useDebouncedCallback((e: any) => {
        setQuery((prev) => ({ ...prev, name: e.target.value }));
        if (props.onSearch) props.onSearch(e.target.value)
    }, 300);
    return (
        <FilterBar
            onSubmit={(e) => {
                e.preventDefault();
                if (props.onFilter) {
                    props.onFilter(query)
                }
            }}
            onCancle={() => {
                setQuery({
                    from: null,
                    to: null
                });
                if (props.onFilter) {
                    props.onFilter({})
                }
            }}
            onChange={onSearch} searchLabel="search requests">
            <div className="flex gap-2 w-full flex-wrap">
                <DateFilter
                    from={query?.from}
                    to={query?.to}
                    onChange={(e) => setQuery((prev) => ({ ...prev, ...e }))}
                />
                <div className="flex flex-col w-1/2 max-w-xs">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-neutral-50">
                        State:
                    </label>
                    <Select
                        id="countries"

                        onChange={(e) => {
                            const id =
                                e.target.options[e.target.selectedIndex].dataset.id;
                            setQuery((prev) => ({ ...prev, state: id as any }));
                        }}
                        className="rounded-lg min-w-[195px] w-full"
                    >
                        {[
                            { name: "All", id: "all" },
                            { name: "Tickets", id: "tickets" },
                            { name: "New", id: RequestState.IDLE },
                            { name: "Accept", id: RequestState.ACCEPT },
                            { name: "In Progress", id: RequestState.IN_PROGRESS },
                            { name: "Sent", id: RequestState.SENT },
                            { name: "Reject", id: RequestState.REJECT },
                        ].map((item) => {
                            return (
                                <option key={item.id} data-id={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}

                    </Select>
                </div>
            </div>
        </FilterBar>
    )
}