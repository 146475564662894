import { create } from "zustand";
import { produce } from 'immer'
interface FollowupStore {
  followUp: gapi.client.calendar.Event | null;
}
export const useFollowupStore = create<FollowupStore>((set) => ({
  followUp: null,

}));
export const setFollowup = (followUp: gapi.client.calendar.Event | null) =>
  useFollowupStore.setState(() => ({
    followUp,
  }));



export const clearFollowupStore = () => useFollowupStore.setState(produce((state: FollowupStore) => {
  state.followUp = null
}))