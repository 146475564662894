import {
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  Pagination,
  Select,
} from "flowbite-react";
import moment from "moment";
import { data, Link, useNavigate } from "react-router-dom";
import {
  getRequestStateName,
  Request,
  RequestState,
} from "../../../types/request";
import { getTotalPages } from "../../../utils/pagination";
import { PAGINATION_SIZE } from "../../../constants/pagination";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import { useState } from "react";
import { setFollowup } from "../../../stores/followup-store";
import { FolloupEventMetadata } from "../../../types/followup";
import { BsFillTrashFill } from "react-icons/bs";
import DateFilter from "../../../components/filters/date-filter";
import { SquareSkeleton } from "../../../components/skeleton";

export default function PatientRequestsTable(props: { patientId: string }) {
  const [query, setQuery] = useState<{
    state?: RequestState | "all" | "tickets";
    from?: Date | null;
    to?: Date | null;
  }>({
    from: null,
    to: null,
  });
  const [page, setPage] = useState<number>(1);
  const userId = useAuthStore((state) => state.user?._id);
  const requests = useQuery({
    queryKey: ["requests", page, { patient: props.patientId, ...query }],
    queryFn: () =>
      apiCaller<{ length: number; requests: Request[] }>({
        method: "GET",
        url: `/owners/${userId}/requests`,
        params: {
          page,
          size: PAGINATION_SIZE,
          patient: props.patientId,
          ...Object.fromEntries(
            Object.entries(query).filter(([_, value]) => value !== null)
          ),
        },
      }),
    placeholderData: keepPreviousData,
  });
  const queryClient = useQueryClient();
  const changeRequestState = useMutation({
    mutationFn: (data: { requestId: string; state: RequestState }) =>
      apiCaller<void>({
        method: "POST",
        url: `/owners/${userId}/requests/${data.requestId}/state`,
        body: JSON.stringify({ state: data.state }),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    },
  });
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between max-sm:max-h-[57vh] max-sm:min-h-[57vh] h-full">
      <div
        className={
          "shadow-sm w-full flex gap-2 items-end bg-primary-50 dark:bg-primary-950 mb-2 p-4 rounded-md"
        }
      >
        <DateFilter
          from={query?.from}
          to={query?.to}
          onChange={(e) => setQuery((prev) => ({ ...prev, ...e }))}
        />
        <div className="flex flex-col w-1/2 max-w-xs">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            State:
          </label>
          <Select
            id="countries"
            className="bg-white rounded-lg w-full"
            onChange={(e) => {
              const id = e.target.options[e.target.selectedIndex].dataset.id;
              setQuery((prev) => ({ ...prev, state: id as any }));
            }}
          >
            {[
              { name: "All", id: "all" },
              { name: "Tickets", id: "tickets" },
              { name: "New", id: RequestState.IDLE },
              { name: "Accept", id: RequestState.ACCEPT },
              { name: "In Progress", id: RequestState.IN_PROGRESS },
              { name: "Sent", id: RequestState.SENT },
              { name: "Reject", id: RequestState.REJECT },
            ].map((item, index) => {
              return (
                <option key={item.id} data-id={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="overflow-auto rounded-lg border-x border-b border-neutral-200 dark:border-gray-700 sm:min-h-[44vh] sm:max-h-[44vh] max-sm:max-h-[32.5vh] max-sm:min-h-[32.5vh]">
        <table className="table-container">
          <thead className="table-head">
            <th className="table-head-cell rounded-l-lg ">
              service
            </th>
            <th className="table-head-cell">
              patient
            </th>
            <th className="table-head-cell">
              Date
            </th>
            <th className="table-head-cell">
              Status
            </th>
            <th className="table-head-cell rounded-r-lg ">
              <span className="sr-only">Edit</span>
            </th>
          </thead>
          <tbody className="divide-y rounded-lg w-full h-full last:rounded-b-lg">
            {requests.isLoading ? (
              <>
                <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                  <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 ">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 hover:text-primary-700">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs px-2">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                </tr>
                <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                  <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 ">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 hover:text-primary-700">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs px-2">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                </tr>
                <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                  <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 ">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs p-2 hover:text-primary-700">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                  <td className="text-sm max-sm:text-xs px-2">
                    <SquareSkeleton className="w-full h-5" />
                  </td>
                </tr>
              </>
            ) : (
              requests.data?.requests.map((request, indx) => {
                return (
                  <tr
                    onClick={() => navigate(`/requests/${request._id}/chat`)}
                    key={request._id}
                    className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800"
                  >
                    <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                      {request.service}
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      {request.patient.userName}
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                      {moment(request.createdAt).format("DD MMM YYYY, hh:mm")}
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 hover:text-primary-700"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <Dropdown label={request.state} inline arrowIcon={false}>
                        {Object.values(RequestState).map((state) => (
                          <Dropdown.Item
                            key={state}
                            onClick={() => {
                              changeRequestState.mutate({
                                requestId: request._id,
                                state: state,
                              });
                            }}
                          >
                            {getRequestStateName(state)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    </td>
                    <td className="text-sm max-sm:text-xs px-2">
                      <Link
                        to={`/requests/${request._id}/chat`}
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                      >
                        <div className="w-fit relative">
                          {/* <HiChatBubbleLeftRight size={20} /> */}
                          {request.patientNewMessage ? (
                            <p className="absolute -top-2 -right-2 bg-neutral-700 text-white rounded-full flex items-center justify-center text-[10px] w-4 h-4">
                              {request.patientNewMessage}
                            </p>
                          ) : null}
                        </div>
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={page}
        className="text-xs sm:text-sm mt-2"
        previousLabel="prev"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
        onPageChange={setPage}
      />
    </div>
  );
}
export function PatientFollowupsTable(props: { patientId: string }) {
  const [openModal, setOpenModal] = useState<{ add: boolean; delete: boolean }>(
    { add: false, delete: false }
  );
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title: string;
    patient: string;
  }>();
  const calenderId = useAuthStore((state) => state.user?.calender?.id);
  const followups = useQuery({
    queryKey: ["followups", pageToken],
    queryFn: () =>
      apiCaller<gapi.client.calendar.Events>({
        method: "GET",
        url: "/followups",
        params: pageToken
          ? {
            calendarId: calenderId,
            timeMin: new Date().toISOString(),
            singleEvents: false,
            pageToken: pageToken,
            maxResults: PAGINATION_SIZE,
            sharedExtendedProperty: `id=${props.patientId}`,
          }
          : {
            calendarId: calenderId,
            timeMin: new Date().toISOString(),
            singleEvents: false,
            maxResults: PAGINATION_SIZE,
            sharedExtendedProperty: `id=${props.patientId}`,
          },
      }).then((result: gapi.client.calendar.Events) => {
        const nextPageToken = result.nextPageToken;
        setNextPageToken(nextPageToken);
        return result.items;
      }),
    enabled: !!calenderId,
  });
  const queryClient = useQueryClient();
  const deleteFollowup = useMutation({
    mutationFn: (id: string) =>
      apiCaller<void>({
        method: "DELETE",
        url: `/followups/${id}`,
      }),
    onSuccess() {
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      setOpenModal((prev) => ({ ...prev, delete: false }));
      setSelectedItem(undefined);
      queryClient.invalidateQueries({ queryKey: ["followups"] });
    },
  });
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:px-2 sm:px-4">
      <div className="flex flex-col justify-between">
        <div className="relative  border-x border-b rounded-lg border-neutral-200 dark:border-gray-700 overflow-auto sm:max-h-[58vh] sm:min-h-[58vh] max-sm:max-h-[49vh] max-sm:min-h-[49vh]">
          <table className="table-container">
            <thead className="table-head">
              <th className="table-head-cell rounded-l-lg">title</th>
              <th className="table-head-cell ">patient</th>
              <th className="table-head-cell ">date</th>
              <th className="table-head-cell ">attachments</th>
              <th className="table-head-cell ">description</th>
              <th className="table-head-cell rounded-r-lg"></th>
            </thead>
            <tbody className="divide-y rounded-lg w-full h-full last:rounded-b-lg">
              {followups.isLoading ? (
                <>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td
                      colSpan={2}
                      className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 "
                    >
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td
                      colSpan={2}
                      className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 "
                    >
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td
                      colSpan={2}
                      className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 "
                    >
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                </>
              ) : (
                followups.data?.map((followup) => {
                  return (
                    <tr
                      key={followup.id}
                      className="bg-white border-x border-neutral-200 cursor-pointer "
                      onClick={() => {
                        setFollowup(followup);
                        navigate(`/followups/${followup.id}`);
                      }}
                    >
                      <td
                        colSpan={2}
                        className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 "
                      >
                        {followup.summary}
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap">
                        {
                          (
                            followup.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.name
                        }
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap">
                        {moment(followup.start.dateTime).format(
                          "DD MMM YYYY, hh:mm"
                        )}
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                        {(
                          followup.extendedProperties
                            ?.shared as FolloupEventMetadata
                        )?.file_name
                          ? (
                            followup.extendedProperties
                              ?.shared as FolloupEventMetadata
                          ).file_name
                          : "_"}
                      </td>
                      <td className="text-xs sm:text-sm px-1">
                        {followup.description}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenModal((prevState) => ({
                            ...prevState,
                            delete: true,
                          }));
                          setSelectedItem({
                            id: followup.id,
                            title: followup.summary,
                            patient: (
                              followup.extendedProperties
                                ?.shared as FolloupEventMetadata
                            )?.name as string,
                          });
                        }}
                        className="text-xs sm:text-sm px-2"
                      >
                        <button className="rounded-full flex items-center justify-center w-8 h-8 hover:bg-primary-400">
                          <BsFillTrashFill className="text-primary-600" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <ButtonGroup className="mt-2 w-full items-center justify-center">
          <Button
            className="max-sm:text-xs"
            onClick={() => {
              setPageToken(undefined);
              setNextPageToken(undefined);
            }}
            disabled={!pageToken}
          >
            Reset
          </Button>
          <Button
            className="max-sm:text-xs"
            disabled={!nextPageToken}
            onClick={() => {
              setPageToken(nextPageToken);
              setNextPageToken(undefined);
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
      <Modal
        show={openModal.delete}
        onClose={() =>
          setOpenModal((prevState) => ({ ...prevState, delete: false }))
        }
      >
        <Modal.Header className="modal-header">delete followup</Modal.Header>
        <Modal.Body className="">
          <p className="text-base w-full text-center leading-relaxed text-gray-500">
            Are you sure you want to delete this followup ({selectedItem?.title}
            )?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 max-sm:flex-col justify-center gap-4">
          <Button
            className="modal-btn"
            onClick={() => {
              deleteFollowup.mutate(selectedItem?.id as string);
            }}
          >
            delete
          </Button>
          <Button
            className="modal-btn"
            color="gray"
            onClick={() =>
              setOpenModal((prevState) => ({ ...prevState, delete: false }))
            }
          >
            cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
