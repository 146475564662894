import ApexCharts from "apexcharts";
import { useEffect, useRef } from "react";
import { useAuthStore } from "../../../stores/auth-store";
import { Link } from "react-router-dom";
import { useAppStore } from "../../../stores/app-store";
import { useShallow } from "zustand/shallow";
//TODO fix unMount issue of chart
//TODO add fetching indicator for it
export default function Chart() {
  const ref = useRef<HTMLDivElement>(null);
  let chartRef = useRef<ApexCharts>();
  const calender = useAuthStore((state) => state.user?.calender);
  const followupsStatus = useAppStore(
    useShallow((state) => state.followupsStatus)
  );
  useEffect(() => {
    const near = followupsStatus.item?.nearFollowups?.length || 0.5;
    const today = followupsStatus.item?.todayFollowups?.length || 0.5;
    const total = near + today;
    const nearPercentage = (near / total) * 100;
    const todayPercentage = (today / total) * 100;
    const series = [nearPercentage, todayPercentage];
    const getChartOptions = () => {
      return {
        series: series,
        colors: ["#C62E2E", "#FFAD60"],
        chart: {
          height: "100%",
          width: "100%",
          type: "pie",
        },
        stroke: {
          colors: ["white"],
          lineCap: "",
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            size: "100%",
            dataLabels: {
              offset: -25,
            },
          },
        },
        labels: ["Today", "Recent"],
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Inter, sans-serif",
          },
        },
        legend: {
          position: "bottom",
          fontFamily: "Inter, sans-serif",
        },
        yaxis: {
          labels: {
            formatter: function (value: number) {
              return value + "%";
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value: number) {
              return value + "%";
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      };
    };
    if (ref.current && !chartRef.current) {
      chartRef.current = new ApexCharts(ref.current, getChartOptions());
      chartRef.current.render();
    }
    else if (ref.current) {
      chartRef.current?.updateSeries(series)
    }
  }, [followupsStatus]);
  if (!calender?.id) {
    return (
      <div className="dark:bg-primary-950 dark:border-primary-900 flex-col gap-4 h-full !font-pinar shadow-md w-full max-sm:w-full md:max-w-[35%] p-4 rounded-2xl bg-neutral-50">
        <div className="w-full flex items-center justify-between">
          <p className="title capitalize font-medium dark:text-white">recent FollowUps Chart</p>
        </div>
        <div className="w-full h-full flex items-center justify-center min-h-[30vh]">
          <Link
            to={"/followups"}
            className="rounded-md bg-primary-500 text-center px-4 py-2 text-white text-sm max-sm:text-xs"
          >
            Give Access to Use Followups
          </Link>
        </div>
      </div>
    );
  }
  if (followupsStatus.isLoading) {
    return (
      <div className="max-lg:hidden border border-neutral-200 h-full !font-pinar shadow-lg w-fit max-w-[20%] p-1 min-w-[250px] rounded-md bg-neutral-50">
        <div className="p-4">
          <h5 className="leading-none text-xl font-bold text-gray-900 dark:text-white pb-2">
            follow ups
          </h5>
        </div>
        <div
          role="status"
          className="flex items-center justify-center h-56  bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700
          max-w-[20%] p-1 min-w-[250px]
        "
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div
      ref={ref}
      className="border dark:bg-primary-950 dark:border-primary-900  max-sm:hidden border-neutral-100 h-full !font-pinar shadow-sm w-fit max-w-[20%] p-1 min-w-[250px] rounded-2xl bg-neutral-50">
      <div className="p-4 w-full">
        <h5 className="title capitalize font-medium dark:text-white">
          follow ups
        </h5>
      </div>
    </div>
  );
}
