import { Button, Label, TextInput } from "flowbite-react";
import logo from "../../assets/images/logo.png";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { User } from "../../types/user";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuthStore } from "../../stores/auth-store";
import { StatusCodes } from "http-status-codes";
import toastError from "../../utils/toastError";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});
export function Login() {
  const [show, setShow] = useState<boolean>(false);
  const setUser = useAuthStore((state) => state.setUSer);
  const navigate = useNavigate();
  const location = useLocation();
  const login = useMutation({
    mutationFn: (input: { email: string; password: string }) =>
      apiCaller<User>({
        method: "POST",
        url: "/auth/owner/login",
        body: JSON.stringify(input),
        errorHandler: (error) => {
          switch (error.status) {
            case StatusCodes.UNAUTHORIZED:
              return toastError({ title: "Invalid username or password" });
            default:
              return toastError({
                title: "Somthing went wrong please try again later.",
              });
          }
        },
      }),
    onSuccess: (data) => {
      setUser(data);
      const from = (location.state as any)?.from?.pathname || "/";
      navigate(from, { replace: true });
    },
    networkMode: 'always'
  });
  const { register, handleSubmit, formState: { errors, isValidating } } = useForm({
    resolver: yupResolver(loginSchema)
  })
  


  return (
    <div className="w-screen h-screen flex items-center justify-center bg-neutral-50">
      <form
        className="flex w-full  max-w-[400px] flex-col gap-4 xs:shadow-md scale-in max-xs:bg-transparent xs:bg-white rounded-lg p-8 sm:min-w-[400px]"
        onSubmit={handleSubmit(data => {
          login.mutate(data)
        })}
      >
        <div className="flex flex-col w-auto justify-center gap-2 items-center">
          <img src={logo} alt="logo" className="" width={50} height={50} />
          <p className="title !font-bold capitalize text-neutral-700 text-2xl">
            admin panel
          </p>
        </div>
        <div>
          <div className="mb-2 block capitalize">
            <Label htmlFor="email" value="email" />
          </div>
          <TextInput
            type="email"
            placeholder="email@gmail.com"
            {...register('email')}
            color={errors.email ? "failure" : ""}
            helperText={errors.email?.message}
          />
        </div>
        <div className="w-full">
          <div className="mb-2 block capitalize">
            <Label htmlFor="password" value="password" />
          </div>
          <div className=" relative w-full">
            <TextInput
              {...register('password')}
              type={show ? "text" : "password"}
              style={{ paddingRight: "2.5rem" }}
              className=" w-full"
              color={errors.password ? "failure" : ""}
              helperText={errors.password?.message}
            />
            <button
              type="button"
              className="absolute right-4 top-3"
              onClick={(e) => {
                e.stopPropagation();
                setShow(!show)
              }}>
              {show ? <FaEye className="text-neutral-600" /> : <FaEyeSlash className="text-neutral-600" />}
            </button>
          </div>
        </div>
        <div>
          <p className="max-sm:text-xs sm:text-sm text-neutral-600 capitalize">if you want to signup، contact us.</p>
        </div>
        <Button className="rounded-full" type="submit" disabled={login.isPending || isValidating}>
          Login
        </Button>
      </form>
    </div>
  );
}
