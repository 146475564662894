import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, ButtonGroup, Modal } from "flowbite-react";
import { useState } from "react";
import { FolloupEventMetadata } from "../../../types/followup";
import { BsFillTrashFill } from "react-icons/bs";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import moment from "moment";
import { setFollowup } from "../../../stores/followup-store";
import { useNavigate } from "react-router-dom";
import { SquareSkeleton } from "../../../components/skeleton";
import FollowupsTableFilter from "./FollowupsTableFilter";
import { PAGINATION_SIZE } from "../../../constants/pagination";

export default function FollowupsTable() {
  const [filter, setFilter] = useState<gapi.client.calendar.EventsListParameters>()
  const [openModal, setOpenModal] = useState<{ add: boolean; delete: boolean }>(
    { add: false, delete: false }
  );
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title: string;
    patient: string;
  }>();
  const calenderId = useAuthStore((state) => state.user?.calender?.id);
  const { data: followups, isLoading } = useQuery({
    queryKey: ["followups", pageToken, filter],
    queryFn: async () => {
      const _filter = {
        singleEvents: false,
        maxResults: PAGINATION_SIZE,
        sharedExtendedProperty: ['type=followup'],
        ...filter
      }
      _filter['calendarId'] = calenderId;
      if (pageToken) {
        _filter["pageToken"] = pageToken;
      }
      const result_3 = await apiCaller<gapi.client.calendar.Events>({
        method: "GET",
        url: "/followups",
        params: _filter,
      });
      const nextPageToken = result_3.nextPageToken;
      setNextPageToken(nextPageToken);
      return result_3.items;
    },
    enabled: !!calenderId,
  });
  const queryClient = useQueryClient();
  const deleteFollowup = useMutation({
    mutationFn: (id: string) =>
      apiCaller<void>({
        method: "DELETE",
        url: `/followups/${id}`,
      }),
    onSuccess() {
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      setOpenModal((prev) => ({ ...prev, delete: false }));
      setSelectedItem(undefined);
      queryClient.invalidateQueries({ queryKey: ["followups"] });
    },
  });
  const navigate = useNavigate();

  return (
    <div className="w-full h-full overflow-auto max-h-[calc(100vh_-_200px)] flex flex-col max-sm:gap-2 sm:gap-4 max-sm:px-2 sm:px-4">
      <FollowupsTableFilter
        onFilter={(filter) => setFilter(filter)}
      />
      <div className="flex flex-col w-full h-full justify-between">
        <div className="relative border-x rounded-lg border-b dark:border-gray-700 border-neutral-200 overflow-auto max-sm:min-h-[calc(100vh_-_320px)] max-sm:max-h-[calc(100vh_-_320px)] sm:min-h-[calc(100vh_-_300px)] sm:max-h-[calc(100vh_-_300px)] w-full h-full">
          <table className="table-container mb-1 ">
            <thead className="table-head">
              <th className="table-head-cell rounded-l-lg">title</th>
              <th className="table-head-cell ">patient</th>
              <th className="table-head-cell ">phone</th>
              <th className="table-head-cell">date</th>
              {/* <th className="table-head-cell">meet</th> */}
              <th className="table-head-cell">attachments</th>
              <th className="table-head-cell">description</th>
              <th className="table-head-cell rounded-r-lg"></th>
            </thead>
            <tbody className="divide-y w-full h-full">
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                  return (
                    <tr key={item} className="max-h-max bg- dark:bg-primary-950 hover:bg-neutral-100 cursor-pointer shadow-sm border-0">
                      <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                      <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                        <SquareSkeleton className="w-full h-full max-h-10" />
                      </td>
                    </tr>
                  )
                })
              ) : (
                followups ?
                  followups?.map((followup) => {
                    return (
                      <tr
                        key={followup.id}
                        className=" shadow-sm border-0 cursor-pointer"
                        onClick={() => {
                          setFollowup(followup);
                          navigate(`/followups/${followup.id}`);
                        }}
                      >
                        <td
                          className="text-neutral-900 dark:text-white text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 font-medium "
                        >
                          {followup.summary}
                        </td>
                        <td className="text-neutral-900 dark:text-white text-xs sm:text-sm px-1 text-nowrap">
                          {
                            (
                              followup.extendedProperties
                                ?.shared as FolloupEventMetadata
                            )?.name
                          }
                        </td>
                        <td className="text-neutral-900 dark:text-white text-xs sm:text-sm px-1 text-nowrap">
                          {(
                            followup.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.phone || "-"}
                        </td>
                        <td className="text-neutral-900 dark:text-white text-xs sm:text-sm px-1 text-nowrap">
                          {moment(followup.start.dateTime).format(
                            "DD MMM YYYY, hh:mm"
                          )}
                        </td>
                        {/* <td className="text-neutral-900 underline dark:text-white text-xs sm:text-sm px-1 text-nowrap">
                          <a href={followup.hangoutLink}>Meet Link</a>
                        </td>
                         */}
                        <td className="text-neutral-900 dark:text-white text-xs sm:text-sm px-1 text-nowrap text-center">
                          {(
                            followup.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.file_name
                            ? (
                              followup.extendedProperties
                                ?.shared as FolloupEventMetadata
                            ).file_name
                            : "_"}
                        </td>
                        <td className="text-neutral-900 dark:text-white text-xs sm:text-sm px-1">
                          {followup.description}
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenModal((prevState) => ({
                              ...prevState,
                              delete: true,
                            }));
                            setSelectedItem({
                              id: followup.id,
                              title: followup.summary,
                              patient: (
                                followup.extendedProperties
                                  ?.shared as FolloupEventMetadata
                              )?.name as string,
                            });
                          }}
                          className="text-neutral-900 dark:text-white text-xs sm:text-sm px-2"
                        >
                          <button className="rounded-full flex items-center justify-center w-8 h-8 hover:bg-primary-400">
                            <BsFillTrashFill className="text-primary-600" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <p className="empty-msg">
                    no patients found!!!
                  </p>
              )}
            </tbody>
          </table>
        </div>
        <ButtonGroup className="mt-1 w-full items-center justify-center">
          <button
            className="border border-neutral-300 text-neutral-400 text-sm max-sm:text-xs px-2 py-[6px] rounded-l-lg"
            onClick={() => {
              setPageToken(undefined);
              setNextPageToken(undefined);
            }}
            disabled={!pageToken}
          >
            Reset
          </button>
          <button
            className="border border-neutral-300 text-neutral-400 text-sm max-sm:text-xs px-2 py-[6px] rounded-r-lg"
            disabled={!nextPageToken}
            onClick={() => {
              setPageToken(nextPageToken);
              setNextPageToken(undefined);
            }}
          >
            Next
          </button>
        </ButtonGroup>
      </div>
      <Modal
        show={openModal.delete}
        onClose={() =>
          setOpenModal((prevState) => ({ ...prevState, delete: false }))
        }
      >
        <Modal.Header className="modal-header">delete followup</Modal.Header>
        <Modal.Body className="">
          <p className="text-base w-full text-center leading-relaxed text-gray-500">
            Are you sure you want to delete this followup ({selectedItem?.title}
            )?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 max-sm:flex-col justify-center gap-4">
          <Button
            className="modal-btn"
            onClick={() => {
              deleteFollowup.mutate(selectedItem?.id as string);
            }}
          >
            delete
          </Button>
          <Button
            className="modal-btn"
            color="gray"
            onClick={() =>
              setOpenModal((prevState) => ({ ...prevState, delete: false }))
            }
          >
            cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
