import { useQueryClient } from "@tanstack/react-query";
import { clearFollowupStore, } from "../stores/followup-store";
import { clearAppStore } from "../stores/app-store";
import { clearAuthStore } from "../stores/auth-store";
import { clearChatStore } from "../stores/chat-store";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";



export default function useLogout() {
    const client = useQueryClient();
    const navigate = useNavigate();


    const logout = useCallback(() => {
        client.clear();
        clearAppStore();
        clearAuthStore();
        clearFollowupStore();
        clearChatStore();
        navigate('/login', { replace: true })
    }, [])

    return [logout]

    //TODO Call logout to invaidate cookie
}