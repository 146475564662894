
export default function EmbeddedGoogleCalender({
  calenderId,
  timeZone,
}: {
  calenderId?: string;
  timeZone?: string;
}) {
  const params = {} as Record<string, any>;
  if (calenderId) params["src"] = calenderId;
  if (timeZone) params["ctz"] = timeZone;
  const urlParams = new URLSearchParams(params);
  const calenderURL = `https://calendar.google.com/calendar/embed?${urlParams.toString()}`;
  return (
    <div className="w-full sm:h-[440px] max-sm:h-[400px] rounded-lg overflow-hidden shadow-lg">
      <iframe
        id="google-calender-iframe"
        title="google-calender-iframe"
        src={calenderURL}
        className="w-full max-sm:max-h-[53vh] max-sm:min-h-[53vh] h-full border-0"
      />
    </div>
  );
}
