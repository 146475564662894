import { useQuery } from "@tanstack/react-query";
import apiCaller from "../utils/api-caller";
import { useAuthStore } from "../stores/auth-store";
import { DEFAULT_PULL } from "../constants/api";
import { useEffect } from "react";
import { AppStore, useAppStore } from "../stores/app-store";
import { produce } from 'immer'
import { Request, RequestsStatus } from "../types/request";



export default function useRequestsBackgroundFetch() {
    const userId = useAuthStore((state) => state.user?._id);
    const status = useQuery({
        queryKey: ["requests", "status"],
        queryFn: () =>
            apiCaller<{
                totalNewRequest: number;
                newRequests: Request[];
                totalTickets: number;
                tickets: Request[];
            }>({
                url: `/owners/${userId}/requests/status`,
                method: "GET",
            }),
        ...DEFAULT_PULL,
    });

    useEffect(() => {
        useAppStore.setState(produce((state: AppStore) => {
            state.requestsStatus.error = status.error;
            state.requestsStatus.isError = status.isError;
            state.requestsStatus.isLoading = status.isLoading;
            state.requestsStatus.isFetching = status.isFetching;
            state.requestsStatus.item = status.data as RequestsStatus
        }))
    }, [status])

}