import React from 'react';

const ThreeDotLoader: React.FC = () => {
  return (
    <div className="flex items-center justify-center space-x-[2.5px]">
      <div className="w-1 h-1 bg-neutral-500 rounded-full animate-bounce" style={{ animationDelay: '0s' }} />
      <div className="w-1 h-1 bg-neutral-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
      <div className="w-1 h-1 bg-neutral-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
    </div>
  );
};

export default ThreeDotLoader;
