import { Button, FloatingLabel, Modal } from "flowbite-react";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiCaller, { ApiError } from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import toastError from "../../utils/toastError";
import { StatusCodes } from "http-status-codes";
import { Patient } from "../../types/patient";
import Countdown from "./components/Countdown";
import LocationInput from "../../components/inputs/location-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
const schema = yup.object().shape({
  email: yup.string().email().required(),
  userName: yup.string().required(),
  phone: yup.string().required(),
  location: yup.object().shape({
    province: yup.string().required(),
    city: yup.string().required(),
  })
});

export default function AddPatientModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { register, handleSubmit, control, reset, formState: { errors, isValidating } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      location: {
        province: '',
        city: ''
      }
    }
  })
  const userId = useAuthStore((state) => state.user?._id);
  const client = useQueryClient();
  function handleClose() {
    addPatient.reset();
    confirmAdd.reset();
    reset()
    onClose();
  }


  const addPatient = useMutation({
    mutationFn: (data: Record<string, any>) =>
      apiCaller<void>({
        method: "POST",
        url: `/owner/${userId}/patients`,
        body: JSON.stringify(data),
        errorHandler(error) {
          switch (error.status) {
            case StatusCodes.CONFLICT:
              if (error.result?.message)
                return toastError({ title: "Already in your patient list" });
          }
        },
      }),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["patients"],
      });
      handleClose();
    },
    networkMode: 'always'
  });
  const confirmAdd = useMutation({
    mutationFn: (patientId: string) =>
      apiCaller<void>({
        method: "POST",
        url: `/owner/${userId}/patients/confirm/${patientId}`,
        params: { action: "confirm-add" },
      }),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["patients"],
      });
      handleClose();
    },
    networkMode: "always"
  });


  const shouldConfirm =
    addPatient.isError &&
    (addPatient.error as unknown as ApiError)?.result?._id;
  const patient = (addPatient.error as unknown as ApiError)?.result as Patient;
  return (
    <Modal position="center" show={show} onClose={() => {
      if (addPatient.isPending || confirmAdd.isPending) return
      handleClose()
    }}>
      {shouldConfirm ? (
        <>
          <Modal.Header className="modal-header">Confirm Add</Modal.Header>
          <Modal.Body>
            <p>{patient.userName}</p>
            <p>{patient.email}</p>
            <p>
              {patient.province}-{patient.city}
            </p>
            <Countdown time={120} />
          </Modal.Body>
          <Modal.Footer className=" border-0 justify-center gap-4">
            <Button
              className="modal-btn"
              type="button"
              disabled={confirmAdd.isPending}
              onClick={() => {
                confirmAdd.mutate(patient._id);
              }}
              isProcessing={confirmAdd.isPending}
            >
              Confirm
            </Button>
            <Button
              className="modal-btn"
              color="gray"
              onClick={handleClose}
              disabled={confirmAdd.isPending}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <form
          onSubmit={handleSubmit((data) => {
            addPatient.mutate({
              email: data.email,
              userName: data.userName,
              phone: data.phone,
              province: data.location.province,
              city: data.location.city
            })
          })}

        >
          <Modal.Header className="modal-header">Add Patient</Modal.Header>
          <Modal.Body className="min-h-[39vh]">

            <FloatingLabel
              {...register('userName')}
              variant="outlined"
              label="userName"
              color={errors.userName ? "error" : "default"}
              helperText={errors.userName?.message}
              className="mb-3"
            />
            <Controller
              control={control}
              name='location'
              render={({ field }) => (
                <LocationInput
                  value={field.value}
                  onChange={(pr, city) => field.onChange({
                    province: pr,
                    city
                  })}

                  error={!!errors.location}
                />
              )}
            />
            <div className="relative my-3">
              <span className="absolute end-4 top-4 text-gray-500 dark:text-gray-400">
                <svg className="w-4 h-4 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>
              </span>
              <FloatingLabel
                {...register('email')}
                variant="outlined"
                label="email"
                type="email"
                color={errors.email ? "error" : "default"}
                helperText={errors.email?.message}
              />
            </div>

            <div className="relative">
              <span className="absolute end-4 top-4 text-gray-500 dark:text-gray-400">
                <svg className="w-4 h-4 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                </svg>
              </span>
              <FloatingLabel
                {...register('phone')}
                variant="outlined"
                label="phone"
                maxLength={11}
                type="tel"
                color={errors.phone ? "error" : "default"}
                helperText={errors.phone?.message}
              />
              <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:start-6 peer-focus:start-0 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Phone number</label>
            </div>

          </Modal.Body>
          <Modal.Footer className=" border-0 justify-center gap-4">
            <Button
              className="modal-btn"
              type="submit"
              disabled={addPatient.isPending}
              isProcessing={addPatient.isPending || isValidating}
            >
              Add
            </Button>
            <Button
              className="modal-btn"
              color="gray"
              onClick={handleClose}
              disabled={addPatient.isPending}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}
