export enum RequestState {
  IDLE = "idle",
  ACCEPT = "accept",
  IN_PROGRESS = "in-progress",
  SENT = "sent",
  REJECT = "reject",
}

export function getRequestStateName(state: RequestState) {
  switch (state) {
    case RequestState.IDLE:
      return "Idle";
    case RequestState.ACCEPT:
      return "Accept";
    case RequestState.IN_PROGRESS:
      return "In progress";
    case RequestState.SENT:
      return "Sent";
    case RequestState.REJECT:
      return "Reject";
  }
}
