import { Avatar as FlowAvatar } from "flowbite-react";

interface AvatarProps {
    userName: string;
    fromUser: boolean;
}

export default function Avatar({ userName, fromUser }: AvatarProps) {
    if (fromUser) return null;
    const initials = userName
        .split(" ")
        .map((item) => item[0])
        .join("");

    return (
        <FlowAvatar
        rounded
        placeholderInitials={initials}/>
    );
}
