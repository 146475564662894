import { Dropdown, ListGroup } from "flowbite-react";
import { useState } from "react";
import { setFollowup } from "../../stores/followup-store";
import { FolloupEventMetadata } from "../../types/followup";
import { useAuthStore } from "../../stores/auth-store";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { SquareSkeleton } from "../../components/skeleton";
import { useAppStore } from "../../stores/app-store";
import { useShallow } from "zustand/shallow";
import ThreeDotLoader from "../../components/loader/threedotLoader";
//TODO add Fetching indicator to it
export default function RecentFollowUps() {
  const [filter, setFilter] = useState<"All" | "Today">("All")
  const navigate = useNavigate();
  const calender = useAuthStore((state) => state.user?.calender);
  const followupsStatus = useAppStore(
    useShallow((state) => state.followupsStatus)
  );

  if (!calender?.id)
    return (
      <div className="flex-col gap-4 !font-pinar shadow-md w-full p-4 h-full min-w-max rounded-2xl dark:bg-primary-950 dark:border-primary-900 bg-neutral-50">
        <div className="w-full flex items-center justify-between">
          <p className="title capitalize flex items-center gap-2 font-medium dark:text-white">recent FollowUps
            {followupsStatus.isFetching ?
              <div className="flex items-center gap-1 text-xs lowercase text-neutral-700">
                (   updating
                <ThreeDotLoader />)
              </div> : null}
          </p>
          <p className="w-fit h-auto text-neutral-500 text-sm dark:text-neutral-100">no items!</p>
        </div>
        <div className="w-full h-full flex items-center justify-center min-h-[30vh]">
          <Link
            to={"/followups"}
            className="rounded-md bg-primary-500 px-4 py-2 text-white text-sm max-sm:text-xs"
          >
            Give Access to Use Followups
          </Link>
        </div>
      </div>
    );
  const Data = filter === "All" ? followupsStatus.item?.nearFollowups : followupsStatus.item?.todayFollowups;

  return (
    <div className="bg-neutral-50 dark:bg-primary-950 dark:border-primary-900 gap-3 border h-full border-neutral-100 flex flex-col rounded-2xl py-3 max-sm:px-3 sm:px-5 shadow-sm  w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium dark:text-white">new FollowUps</p>
        <Dropdown label={filter} className="p-0">
          <Dropdown.Item className="text-sm max-sm:text-xs" onClick={() => setFilter("All")}>All</Dropdown.Item>
          <Dropdown.Item className="text-sm max-sm:text-xs" onClick={() => setFilter("Today")}>Today</Dropdown.Item>
        </Dropdown>
      </div>
      <div className="relative w-full min-h-[25vh] max-h-max h-full overflow-x-auto">
        {followupsStatus.isLoading ? (
          <ListGroup className="w-full h-full max-h-max min-w-full overflow-auto">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              console.log({ isLoading: followupsStatus.isLoading, item });
              return (
                <ListGroup.Item key={item}>
                  <div className="grid w-full items-center grid-cols-3 space-x-2">
                    <SquareSkeleton className="col-span-1 h-5" />
                    <SquareSkeleton className="col-span-1 h-5" />
                    <SquareSkeleton className="col-span-1 h-5" />
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : Data &&
          Data.length > 0 ? (
          <ListGroup className="w-full max-h-max h-full min-w-full overflow-auto">
            {Data.map((item) => {
              return (
                <ListGroup.Item
                  key={item.id}
                  onClick={() => {
                    setFollowup(item);
                    navigate(`/followups/${item.id}`);
                  }}
                  className="w-full"
                >
                  <div className="flex w-full items-center max-lg:gap-6">
                    <p className="text-neutral-900 dark:text-white w-full text-wrap text-normal">
                      {item.summary}
                    </p>
                    <p className="text-neutral-900 dark:text-white w-full text-nowrap text-sm font-normal">
                      {(
                        item.extendedProperties
                          ?.shared as FolloupEventMetadata
                      )?.name || "not selected"}
                    </p>
                    <p className="text-neutral-900 dark:text-white text-xs w-full text-nowrap text-center font-light">
                      {moment(item.start.dateTime).format(
                        "MMM DD YYYY, HH:mm"
                      )}
                    </p>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <p className="empty-msg w-full">
            you dont have any followup {filter === "Today" && "today"}.
          </p>
        )}
      </div>
    </div>
  );
}
