import { ListGroup, Spinner } from "flowbite-react";
import moment from "moment";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { Request } from "../../types/request";
import { services } from "../../constants/services";
import { useNavigate } from "react-router-dom";
import { SquareSkeleton } from "../../components/skeleton";
import { useEffect, useState } from "react";
import ThreeDotLoader from "../../components/loader/threedotLoader";

export default function RecentTickets({
  total,
  tickets,
  isLoading,
  isFetching
}: {
  total: number;
  tickets: Request[];
  isLoading: boolean;
  isFetching: boolean
}) {
  const router = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 10 : 100));
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="relative bg-neutral-50 dark:bg-primary-950 dark:border-primary-900 gap-2 flex h-full flex-col border border-neutral-100 rounded-2xl py-3 px-5 shadow-sm lg:max-w-[80%] w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium flex items-center gap-2 dark:text-white">new Tickets {isFetching ?
          <div className="flex items-center gap-1 text-xs lowercase text-neutral-700">
         (   updating
            <ThreeDotLoader />)
          </div>
          : null}</p>
        <p className="w-fit h-auto text-neutral-500 text-sm dark:text-neutral-200">{total} items</p>
      </div>
      <div className="relative h-full max-h-max min-h-[30vh] overflow-x-auto">
        {isLoading ? (
          <ListGroup>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
              <ListGroup.Item key={item}>
                <div className="grid w-full items-center grid-cols-4 space-x-2">
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : tickets.length > 0 ? (
          <ListGroup className="w-full max-h-max overflow-auto">
            {tickets.map((ticket) => {
              return (
                <ListGroup.Item
                  className="w-max"
                  key={ticket._id}
                  onClick={() => router(`/requests/${ticket._id}/chat`)}
                >
                  <div className="grid w-full items-center grid-cols-5 space-x-2">
                    <div className="flex w-full flex-nowrap items-center col-span-2 gap-1">
                      {
                        services.find((item) => item.name === ticket.service)
                          ?.icon
                      }
                      <p className="text-neutral-900 dark:text-white text-sm text-normal">
                        {ticket.service}
                      </p>
                    </div>
                    <p className="text-neutral-900 dark:text-white text-nowrap w-full text-sm font-normal">
                      {ticket.patient.userName}
                    </p>
                    <p className="text-neutral-900 dark:text-white w-full text-nowrap text-xs text-center font-light">
                      {moment().format("MMM DD, hh:mm")}
                    </p>
                    <div
                      className={`${ticket.patientNewMessage <= 0 && "hidden"
                        } w-full relative flex flex-nowrap justify-end items-center`}
                    >
                      <HiChatBubbleLeftRight size={20} color="gray" />
                      <div className="w-4 -mt-3 h-4 rounded-full flex self-center items-center justify-center text-white bg-primary-500 border text-[10px]">
                        {ticket.patientNewMessage}
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <p className="empty-msg">
            no recent tickets yet!!
          </p>
        )}
      </div>
    </div>
  );
}
