import { TbTruckDelivery } from "react-icons/tb";
import {
  FaUserMd,
  FaBell,
  FaCapsules,
  FaFilePrescription,
  FaSyringe,
  FaHeartbeat,
  FaWeight,
} from "react-icons/fa";
import { GiMedicinePills } from "react-icons/gi";
import { BiTestTube } from "react-icons/bi";
import { GiChemicalDrop } from "react-icons/gi";
import { MdVaccines } from "react-icons/md";
import { MdBloodtype } from "react-icons/md";
import { MdLocalPharmacy } from "react-icons/md";
import doctorIcon from "../../src/assets/images/services-doctor.png";

export const services = [
  {
    name: "New Prescription Submission",
    icon: <BiTestTube size={32} className="text-primary-400 hover:text-primary-600" />
  },
  {
    name: "Pharmacy Delivery",
    icon:
      <TbTruckDelivery
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Consultation",
    icon:
      <FaUserMd
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Medication Reminder",
    icon:
      <FaBell
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Refill",
    icon:
      <FaCapsules
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Online Prescription",
    icon:
      <FaFilePrescription
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },

  {
    name: "Medication Review",
    icon: <GiMedicinePills
      size={32}
      className="text-primary-400 hover:text-primary-600"
    />
  },
  {
    name: "Injections",
    icon:
      <FaSyringe
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Blood Pressure Measure & Management",
    icon:
      <FaHeartbeat
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Diabetes Services",
    icon:
      <MdBloodtype
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Weight Management",
    icon:
      <FaWeight
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Compounding",
    icon:
      <GiChemicalDrop
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Travel Medicine Vaccination",
    icon:
      <MdVaccines
        size={32}
        className="text-primary-400 hover:text-primary-600"
      />
  },
  {
    name: "Talk To Doctor",
    icon: <img src={doctorIcon} alt="doctor" width={45} height={45} className="w-auto h-auto" />
  },

  {
    name: " Pharmacists Prescribing For Minor Ailments",
    icon: <MdLocalPharmacy
      size={32}
      className="text-primary-400 hover:text-primary-600"
    />
  }
]
