import { StatusCodes } from "http-status-codes";
import toastError from "./toastError";
export type ApiError = {
  status: StatusCodes;
  statusText: string;
  result: Record<string, any>;
};
export const status2Errors =
  (errorMapper: Record<StatusCodes, string>) => (error: ApiError) => {
    const status = error.status;
    if (status in errorMapper) {
      toastError({ title: errorMapper[status] });
      return;
    }
    throw error;
  };
function defualtErrorHandler(error: ApiError) {
  if (error.status === StatusCodes.UNAUTHORIZED) {
    return (window.location.href = "/login");
  }
  console.error(error.statusText);
}
function removeSlash(url: string) {
  const exp = /^\/+/;
  return url.replace(exp, "");
}

export default async function apiCaller<R = any, P = Record<string, any>>({
  url,
  headers,
  body,
  method,
  params,
  formData,
  errorHandler,
  external = false,
}: {
  url: string;
  method: string;
  headers?: Record<string, string>;
  body?: BodyInit | null;
  formData?: boolean;
  params?: P;
  external?: boolean;
  errorHandler?: (error: ApiError) => void;
}) {
  const urlParams = new URLSearchParams(params as any);
  const baseURL = external ? "" : `${process.env.REACT_APP_API_ROUTE}/`;
  const res = await fetch(
    `${baseURL}${removeSlash(url)}${params ? `?${urlParams.toString()}` : ""}`,
    {
      method,
      headers: formData
        ? {}
        : {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },

      body,
      credentials: "include",
    }
  ).catch((err) => {
    return {
      ok: false,
      status: -1,
      statusText: "",
      json: async () => "",
    };
  });
  if (!res.ok) {
    const error = {
      status: res.status,
      statusText: res.statusText,
      result: await res.json(),
    } as ApiError;
    if (errorHandler) {
      try {
        errorHandler(error);
      } catch (err) {
        defualtErrorHandler(error);
      }
      throw error;
    } else {
      defualtErrorHandler(error);
      throw error;
    }
  }
  //@ts-ignore
  const contentType = res.headers.get("Content-Type");
  //@ts-ignore
  const contentLength = res.headers.get("Content-Length");
  if (contentLength === "0") return undefined as R;
  if (contentType && contentType.includes("application/json")) {
    return res.json() as R;
  }
  return res as R;
}
