import { Button, Pagination } from "flowbite-react";
import { useState } from "react";
import AddPatientModal from "./modals";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../stores/auth-store";
import apiCaller from "../../utils/api-caller";
import { Patient } from "../../types/patient";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { FilterBar } from "../../components/filters/filterbar";
import Header from "../../components/header";
import { useDebouncedCallback } from "use-debounce";
import { SquareSkeleton } from "../../components/skeleton";

export function Patients() {
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const onSearch = useDebouncedCallback((e: any) => {
    setName(e.target.value);
  }, 300);
  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.user?._id);
  const { data, isLoading } = useQuery({
    queryKey: ["patients", page, { name }],
    queryFn: () =>
      apiCaller<{
        length: number;
        patients: Patient[];
      }>({
        method: "GET",
        url: `/owner/${userId}/patients`,
        params: { page, size: PAGINATION_SIZE, name },
      }),

    placeholderData: keepPreviousData,
    ...DEFAULT_CACHE_CONFIG,
  });
  return (
    <div className="w-full h-full max-md:px-4 flex flex-col gap-4 sm:py-4">
      <Header title="patients">
        <Button
          className="add-btn"
          onClick={() => setShow(true)}
        >
          <p className="w-full text-sm max-sm:text-xs font-medium capitalize">
            Add Patient
          </p>
        </Button>
      </Header>
      <div className="w-full h-full sm:max-h-[calc(100vh_-_180px)] max-sm:max-h-[calc(100vh_-_200px)] overflow-auto flex flex-col gap-2">
        <FilterBar
          onChange={onSearch}
          onCancle={() => {
            setName('')
          }}
          onSubmit={e => {
            e.preventDefault();
          }}
          searchLabel="search patients"
          searchPlaceHolder="Search by username,phone"
        />
        <div className="flex flex-col justify-between h-full">
          <div className="relative bg-transparent border-x border-b h-full rounded-lg border-neutral-200 dark:border-gray-700 sm:max-h-[calc(100vh_-_280px)] sm:min-h-[calc(100vh_-_280px)] max-sm:max-h-[calc(100vh_-_295px)] max-sm:min-h-[calc(100vh_-_305px)] overflow-auto ">
            <table className="table-container">
              <thead className="table-head">
                <th className="table-head-cell rounded-bl-lg">username</th>
                <th className="table-head-cell ">phone</th>
                <th className="table-head-cell">email</th>
                <th className="table-head-cell capitalize rounded-br-lg">location</th>
              </thead>
              <tbody className="divide-y w-full">
                {isLoading ?
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                    return (
                      <tr key={item} className="max-h-max bg-white dark:bg-primary-950 hover:bg-neutral-100 cursor-pointer shadow-sm dark:border-primary-900">
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                      </tr>
                    )
                  })
                  : (
                    data?.patients ?
                      data?.patients.map((patient, indx) => {
                        return (
                          <tr
                            onClick={() => navigate(`/patients/${patient._id}`)}
                            key={patient._id}
                            className="w-full h-fit hover:bg-neutral-100 dark:hover:bg-primary-dark-50 cursor-pointer shadow-sm border-0"
                          >
                            <td className="text-xs p-2 h-fit text-neutral-900 dark:text-white sm:text-sm whitespace-nowrap font-medium">
                              {patient.userName}
                            </td>
                            <td className="text-xs p-2 h-fit text-neutral-900 dark:text-white sm:text-sm whitespace-nowrap font-medium">
                              {patient.phone}
                            </td>
                            <td className="text-xs sm:text-sm p-2 h-fit text-neutral-900 dark:text-white">
                              {patient.email}
                            </td>
                            <td className="text-xs sm:text-sm p-2 h-fit text-neutral-900 dark:text-white">
                              {patient.province}-{patient.city}
                            </td>
                          </tr>
                        );
                      })
                      :
                      <p className="empty-msg">
                        no patients found!!!
                      </p>
                  )}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={page}
            previousLabel="prev"
            className="text-xs sm:text-sm mt-2"
            style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: ".35.cssrem 0" }}
            totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
            onPageChange={(page) => setPage(page)}
          />
        </div>
      </div>
      <AddPatientModal show={show} onClose={() => setShow(false)} />
    </div>
  );
}
