import SideBar from "./sidebar";
import { useEffect, useState } from "react";
import { Modal, Button } from "flowbite-react";
import { useAppStore } from "../stores/app-store";
import { useShallow } from "zustand/shallow";
import { useAuthStore } from "../stores/auth-store";
import NavBar from "./navbar";
import { Outlet, useLocation } from "react-router-dom";
import Appbar from "./appbar";
import useLogout from "../utils/logout";
import useRequestsBackgroundFetch from "../hooks/useRequestsBackgroundFetch";
import useFollowupsBackgroundFetch from "../hooks/useFollowupBackgroundFetch";

export default function Layout({
  showNavbar = true,
}: {
  showNavbar?: boolean;
}) {
  const user = useAuthStore((state) => state.user);
  const [logout] = useLogout();
  const { activeTab, setActiveTab } = useAppStore(
    useShallow((state) => ({
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
    }))
  );
  const [openModal, setOpenModal] = useState(false);
  function toggleModal(a: boolean) {
    setOpenModal(a);
  }
  const location = useLocation();

  useEffect(() => {
    const items = ['dashboard', 'requests', 'patients', 'followups','video-chats'];
    const path = location.pathname;
    const index = items.findIndex(item => path.includes(item));
    if (index >= 0 && activeTab !== items[index]) {
      return setActiveTab(items[index])
    }
    else if (index == -1) {
      setActiveTab(items[0])
    }
  }, [location])

  useRequestsBackgroundFetch();
  useFollowupsBackgroundFetch();


  return (
    <div className="w-screen h-screen bg-white dark:bg-[#1f2937] overflow-hidden flex flex-col">
      <div className="w-full flex h-full">
        <SideBar
          toggleModal={toggleModal}
          active={activeTab}
          handleActiveTab={setActiveTab}
        />
        <div className="w-full md:py-4 md:px-4 lg:px-6 relative h-full">
          <Appbar
            toggleModal={toggleModal}
            active={activeTab}
            handleActiveTab={setActiveTab}
            userName={user?.pharmacy || ""}
          />
          <Outlet />
          {showNavbar && (
            <NavBar active={activeTab} handleActiveTab={setActiveTab} />
          )}
        </div>
      </div>
      <Modal
        position="center"
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header className="modal-header">logout</Modal.Header>
        <Modal.Body>
          <p className="sm:text-base text-sm w-full text-center leading-relaxed text-neutral-600 dark:text-neutral-200">
            Are you sure you want to logout from PHARMACANDA?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-center flex gap-3">
          <Button
            className="modal-btn"
            onClick={() => {
              logout()
              setOpenModal(false);
            }}
          >
            logout
          </Button>
          <Button
            className="modal-btn"
            color="gray"
            onClick={() => setOpenModal(false)}
          >
            cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
