import { useState } from "react";

export default function RecurrencyInput(
    { recurrenceType,
        setRecurrenceType,
        recurrenceInterval,
        setRecurrenceInterval
    }:
        {
            recurrenceType: string,
            setRecurrenceType: (e: string) => void,
            recurrenceInterval: number,
            setRecurrenceInterval: (e: number) => void
        }
) {
    const [showRecurrence, setShowRecurrence] = useState(false);
    return (
        <div className="flex w-full max-sm:flex-col gap-4 items-center">
            <div className="flex w-full flex-col space-y-2">
                <label className="text-sm font-medium text-gray-700">
                    Recurrence
                </label>
                <select
                    required
                    value={recurrenceType}
                    onChange={(e) => {
                        setRecurrenceType(e.target.value);
                        setShowRecurrence(e.target.value !== "none");
                    }}
                    className="block dark:bg-transparent dark:text-white w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                >
                    <option className="text-black" value="none">No repeat</option>
                    <option className="text-black" value="daily">Daily</option>
                    <option className="text-black" value="weekly">Weekly</option>
                    <option className="text-black" value="monthly">Monthly</option>
                    <option className="text-black" value="yearly">Yearly</option>
                </select>
            </div>
            <div className="mt-2 gap-2 flex items-center w-full">
                {showRecurrence && (
                    <>
                        <div className="w-full flex flex-col space-y-2">
                            <span className="text-sm text-gray-500">Repeat every</span>
                            <input
                                type="number"
                                min="1"
                                value={recurrenceInterval}
                                onChange={(e) =>
                                    setRecurrenceInterval(Number(e.target.value))
                                }
                                className="w-full dark:bg-transparent dark:text-white  rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                            />
                        </div>
                        <span className="text-sm text-gray-500">
                            {recurrenceType.toLowerCase()}
                            {recurrenceInterval > 1 ? "s" : ""}
                        </span>
                    </>
                )}
            </div>
        </div>
    )
}