import React from "react";
import { stringToColor } from "../../utils/textToColor";

interface AvatarProps {
  userName: string;
  style?: string;
  online?: boolean
}

export default function Avatar({ userName, style = "", online = false }: AvatarProps) {
  const initials = userName
    .split(" ")
    .map((item) => item[0])
    .join();
  const bgColor = stringToColor(userName);

  return (
    <div
      id="avatar"
      className={`${style} flex items-center relative uppercase justify-center w-[35px] h-[35px] rounded-full text-white text-xs font-medium`}
      style={{ backgroundColor: bgColor }}
    >
      {initials}
      <div className="w-[8px] h-[8px] rounded-full absolute right-[1px] border border-white bottom-[1px] bg-green-400"
        style={{
          display: online ? 'block' : 'none'
        }}
      />
    </div>
  );
}
