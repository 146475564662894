import { useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri"; // Import delete icon
import { useChatStore } from "../../stores/chat-store";
import { useShallow } from "zustand/shallow";
import { useMutation } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { Message, MessageState } from "../../types/message";
import { useAuthStore } from "../../stores/auth-store";

export default function ChatInput({
  chatId,
  onAfterSend,
}: {
  chatId: string;
  onAfterSend: () => void;
}) {
  const [fileName, setFileName] = useState<string | null>(null);
  const state = useChatStore(
    useShallow((state) => ({
      updateChat: state.updateChat,
      updateMessage: state.updateMessage,
      addMessage: state.addMessage,
      resetNewMessages: state.resetNewMessages,
    }))
  );
  const user = useAuthStore((state) => state.user);

  const sendMessageMutation = useMutation({
    mutationFn: (data: { id: string; message: string }) =>
      apiCaller<Message>({
        method: "POST",
        url: `/requests/${chatId}/messages`,
        body: JSON.stringify({ message: data.message }),
      }),
    onError(err, data) {
      state.updateMessage(chatId, data.id, {
        state: MessageState.FAIL,
      });
    },
    onSuccess(res, data) {
      state.updateMessage(chatId, data.id, {
        ...res,
        localId: data.id,
        state: MessageState.SENT,
      });
    },
  });
  const sendFileMessageMutation = useMutation({
    mutationFn: (data: { id: string; data: FormData }) =>
      apiCaller<Message>({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `/requests/${chatId}/messages`,
        body: data.data,
        formData: true,
      }),
    onError(err, data) {
      state.updateMessage(chatId, data.id, {
        state: MessageState.FAIL,
      });
    },
    onSuccess(res, data) {
      state.updateMessage(chatId, data.id, {
        ...res,
        localId: data.id,
        state: MessageState.SENT,
      });
    },
  });

  function sendMessage(message: string) {
    const localId = `${Date.now()}-${Math.random() * 1000}`;
    state.addMessage(chatId, {
      _id: localId,
      localId: localId,
      sender: {
        _id: user?._id as string,
        userName: user?.pharmacy as string,
      },
      message,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      state: MessageState.SENDING,
    });
    sendMessageMutation.mutate({ id: localId, message });
    onAfterSend();
  }
  function sendFile(file: File, message: string) {
    const localId = `${Date.now()}-${Math.random() * 1000}`;
    state.addMessage(chatId, {
      _id: localId,
      localId: localId,
      sender: {
        _id: user?._id as string,
        userName: user?.pharmacy as string,
      },
      file: {
        name: file.name,
        size: file.size,
        url: URL.createObjectURL(file),
      },
      ...(message.trim() && { message }),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      state: MessageState.SENDING,
    });
    const formData = new FormData();
    formData.append("file", file);
    if (message.trim()) {
      formData.append("message", message);
    }
    sendFileMessageMutation.mutate({ id: localId, data: formData });
    onAfterSend();
  }
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      //@ts-ignore
      fileInputRef.current.click();
    }
  };
  const handleDeleteFile = () => {
    setFileName(null); // Clear the file name
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const data = new FormData(e.target as any);
        const form = e.target as HTMLFormElement;
        const message = data.get("message") as string;

        if (fileName) {
          const file = fileInputRef.current?.files?.[0];
          if (file) {
            sendFile(file, message);
            handleDeleteFile();
          }
        } else if (message.trim()) {
          sendMessage(message);
          form.reset();
        }
      }}
      className=" relative w-full h-full"
    >
      {fileName && (
        <div className="flex bottomTotop absolute -top-20 left-0 z-50 w-full max-sm:min-h-[63px] sm:min-h-[73px] max-h-[120px] max-sm:h-[63px] sm:h-[73px] items-center gap-3 max-sm:px-2 max-sm:py-4 sm:p-4 border border-gray-300 rounded-lg bg-gray-50 dark:bg-primary-dark-50 shadow-md ">
          <div className="flex items-center justify-center max-sm:w-8 max-sm:h-8 sm:w-12 sm:h-12 bg-gray-100 rounded-full border border-gray-300">
            <RiAttachment2
              className="text-primary-800 w-6 h-6 max-sm:w-4 max-sm:h-4"
              size={24}
            />
          </div>
          <div className="flex-1">
            <span className="block text-sm truncated font-semibold text-gray-900 text-clamp line-clamp-1">
              {fileName}
            </span>
            <span className="text-xs text-gray-500">File ready to send</span>
          </div>
          <button
            onClick={handleDeleteFile}
            className="text-red-500 hover:bg-red-100 p-2 rounded-full"
          >
            <RiDeleteBin6Line className="w-6 h-6 max-sm:w-5 max-sm:h-5" />
          </button>
        </div>
      )}
      <div className="relative flex items-end">
        <textarea
          name="message"
          className="w-full resize-none max-sm:p-2 sm:p-4 max-sm:min-h-[63px] sm:min-h-[73px] max-h-[120px] max-sm:h-[63px] sm:h-[73px] !pr-20 text-sm text-gray-900 border border-gray-300 dark:border-primary-900 rounded-lg bg-white dark:bg-primary-950 focus:ring-primary-500 focus:border-primary-500 shadow-md"
          placeholder={
            fileName ? "Write Your Caption..." : "Write something..."
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              const message = (e.target as HTMLTextAreaElement).value;
              const file = fileInputRef.current?.files?.[0];
              if (file) {
                sendFile(file, message);
                handleDeleteFile();
              }
              else if (message.trim()) {
                sendMessage(message);
              }
              (e.target as HTMLTextAreaElement).value = "";
            }
          }}
        />
        <button
          type="button"
          onClick={handleAttachmentClick}
          className="absolute max-sm:right-14 sm:right-16 mr-2 p-2 top-1/2 -translate-y-1/2 rounded-full hover:bg-gray-200"
        >
          <RiAttachment2 className="text-primary-800 w-6 h-6 max-sm:w-5 max-sm:h-5" />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => {
            const files = e.target.files;
            if (files?.length) {
              setFileName(files[0].name);
            }
          }}
        />
        <button
          type="submit"
          className="absolute right-4 top-1/2 -translate-y-1/2 bg-primary-700 text-white p-3 rounded-full hover:bg-primary-800 shadow-md focus:ring-4 focus:ring-primary-300"
        >
          <IoSend className=" w-6 h-6 max-sm:w-4 max-sm:h-4" />
        </button>
      </div>
    </form>
  );
}
