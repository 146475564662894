import { useState } from "react";
import MessageCard from "./message";
import apiCaller from "../../utils/api-caller";
import { Message, MessageState } from "../../types/message";
import { useParams } from "react-router-dom";
import { useChatStore } from "../../stores/chat-store";
import { useShallow } from "zustand/shallow";
import ChatInput from "./chatinput";
import { useAuthStore } from "../../stores/auth-store";
import { useQuery } from "@tanstack/react-query";
import { useScrollToBottom } from "./hooks/useScrollToBottom";

const LIMIT_SIZE = 50;
export default function ChatPage() {
  const [since] = useState(() => new Date());
  const { request } = useParams() as { request: string };
  const chatState = useChatStore(useShallow((state) => state.chats[request]));
  const userId = useAuthStore((state) => state.user?._id);

  const chatActions = useChatStore(
    useShallow((state) => ({
      updateChat: state.updateChat,
    }))
  );
  const messagesQuery = useQuery({
    queryKey: ["requests", request, "messages", { since }],
    queryFn: () =>
      apiCaller<{ messages: Message[]; length: number }>({
        method: "GET",
        url: `/requests/${request}/messages`,
        params: {
          since,
          size: 2000,
          page: chatState?.lastPage || 1,
        },
      }).then((res) => {
        const { ids, messages } = res.messages.reduce(
          (prev, message) => {
            prev.ids.push(message._id);
            prev.messages[message._id] = {
              ...message,
              state: MessageState.SENT,
            };
            return prev;
          },
          {
            ids: [] as string[],
            messages: {} as Record<string, Message>,
          }
        );
        const page = chatState?.lastPage || 1;
        const totalFetchSize = page * LIMIT_SIZE;
        const haveMoreMessage = totalFetchSize < res.length;
        chatActions.updateChat(
          request,
          messages,
          ids.reverse(),
          (chatState?.lastPage || 1) + 1,
          haveMoreMessage
        );
        return res;
      }),
    enabled: !!request,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  useQuery({
    queryKey: ["requests", request, "messages", { since, new: true }],
    queryFn: () =>
      apiCaller<{ messages: Message[]; length: number }>({
        method: "GET",
        url: `/requests/${request}/messages`,
        params: {
          since,
          new: true,
        },
      }).then((res) => {
        const { ids, messages } = res.messages.reduce(
          (prev, message) => {
            prev.ids.push(message._id);
            prev.messages[message._id] = {
              ...message,
              state: MessageState.SENT,
            };
            return prev;
          },
          {
            ids: [] as string[],
            messages: {} as Record<string, Message>,
          }
        );
        const page = chatState?.lastPage || 1;
        const totalFetchSize = page * LIMIT_SIZE;
        const haveMoreMessage = totalFetchSize < res.length;
        chatActions.updateChat(
          request,
          messages,
          ids.reverse(),
          (chatState?.lastPage || 1) + 1,
          haveMoreMessage,
          true
        );
        return res;
      }),
    enabled: !!request,
    refetchOnMount: true,
    refetchInterval: 15000,
  });

  const { containerRef, triggerScrollToBottom } =
    useScrollToBottom<HTMLDivElement>(
      [messagesQuery.isLoading],
      messagesQuery.isFetching || messagesQuery.isLoading
    );
  return (
    <div className="w-full h-full flex flex-col gap-2 pt-2 p-2">
      <div
        ref={containerRef}
        className="w-auto flex flex-col gap-4 sm:max-h-[calc(100vh_-_195px)] sm:min-h-[calc(100vh_-_195px)] max-sm:max-h-[calc(100vh_-_165px)] max-sm:min-h-[calc(100vh_-_165px)] h-auto overflow-y-auto"
      >
        {chatState?.ids.map((id) => {
          const message = chatState.messages[id];
          return (
            <div key={id}>
              <div style={{ padding: "10px 0" }}>
                <MessageCard userId={userId as string} {...message} />
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput chatId={request} onAfterSend={triggerScrollToBottom} />
    </div>
  );
}
