import { produce } from "immer";
import { create } from "zustand";
import { RequestsStatus } from "../types/request";
import { createJSONStorage, persist } from "zustand/middleware";
import { BackGroundStoreItem } from "../types/background-store-item";
import { FollowupsStatus } from "../types/followup";


export interface AppStore {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  requestsStatus: BackGroundStoreItem<RequestsStatus>;
  followupsStatus: BackGroundStoreItem<FollowupsStatus>
}
export const useAppStore = create<AppStore>(
  //@ts-ignore
  persist(
    (set, get) => ({
      activeTab: "dashboard",
      setActiveTab(tab) {
        set({ activeTab: tab });
      },
      requestsStatus: {
        item: {
          totalNewRequest: 0,
          newRequests: [],
          totalTickets: 0,
          tickets: [],
        },
        isError: false,
        error: null,
        isLoading: false,
        isFetching: false
      },
      followupsStatus: {
        item: {
          todayFollowups: [],
          nearFollowups: []
        },
        isLoading: false,
        isFetching: false,
        isError: false,
        error: null
      },

    }),

    {
      name: "app-store",
      storage: createJSONStorage(() => localStorage),
      partialize(state) {
        return { activeTab: state.activeTab };
      },
    }
  )
);
export const clearAppStore = () => useAppStore.setState(produce((state: AppStore) => {
  state.activeTab = 'dashboard';
  state.requestsStatus = {
    item: {
      totalNewRequest: 0,
      newRequests: [],
      totalTickets: 0,
      tickets: [],
    },
    isError: false,
    error: null,
    isLoading: false,
    isFetching: false
  }
  state.followupsStatus = {
    item: {
      todayFollowups: [],
      nearFollowups: []
    },
    isLoading: false,
    isFetching: false,
    isError: false,
    error: null
  }

}))