import { useMutation, useQuery } from "@tanstack/react-query";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { useShallow } from "zustand/shallow";
import { Dropdown, Spinner } from "flowbite-react";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { User } from "../../types/user";
import { StatusCodes } from "http-status-codes";

export default function CalenderList() {
  const { user, setUser, resetCalender } = useAuthStore(
    useShallow((state) => ({
      user: state.user,
      setUser: state.setUSer,
      resetCalender: state.resetCalender,
    }))
  );
  const calenders = useQuery({
    queryKey: ["calender-list"],
    queryFn: () =>
      apiCaller<{ id: string; summary: string; timeZone: string }[]>({
        method: "GET",
        url: `/calenders`,
        errorHandler(error) {
          if (error.status === StatusCodes.INTERNAL_SERVER_ERROR) {
            resetCalender();
          }
        },
      }),

    enabled: user?.calender?.access,
    ...DEFAULT_CACHE_CONFIG,
  });
  const setCalenderMutation = useMutation({
    mutationFn: (calender: { id: string; summary: string; timeZone: string }) =>
      apiCaller<void>({
        method: "POST",
        url: "/calenders",
        body: JSON.stringify(calender),
      }),
    onSuccess(_, data) {
      setUser({
        ...(user as User),
        calender: {
          access: true,
          ...data,
        },
      });
    },
  });
  if (calenders.isLoading) return <Spinner size="sm"/>
  return (
    <Dropdown
      label={
        user?.calender?.summary || (
          <p className="text-xs sm:text-sm text-white">Select Calender</p>
        )
      }
    >
      <Dropdown.Header>
        <span className="block sm:text-sm text-xs font-bold text-primary-900 dark:text-primary-400">
          choose google calendar data
        </span>
      </Dropdown.Header>
      {calenders.data?.map((calendar) => {
        return (
          <Dropdown.Item
            className={`text-xs sm:text-sm ${user?.calender?.id === calendar.id && "bg-primary-200 dark:bg-primary-800"
              }`}
            onClick={() => {
              setCalenderMutation.mutate(calendar);
            }}
            key={calendar.id}
          >
            {calendar.summary}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
}
