import { Datepicker } from "flowbite-react";

export default function DateFilter({
  onChange,
  from,
  to,
}: {
  onChange?: (value: Partial<Record<"from" | "to", Date | null>>) => void;
  from?: Date | null;
  to?: Date | null;
}) {
  return (
    <div className="flex flex-col">
      <label className="block mb-2 dark:text-neutral-100 text-sm font-medium text-gray-900">
        Date:
      </label>
      <div className="flex max-sm:hidden">
        <Datepicker
          icon={undefined}
          value={from}
          onChange={(date) => {
            if (!onChange) return;
            if (date === null) onChange({ from: null, to: null });
            onChange({ from: date });
          }}
          name="from"
          placeholder="from"
          label="from"
          className="h-[41.6px] bg-white dark:bg-primary-950"
          style={{
            width: "fit-content",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Datepicker
          icon={undefined}
          name="to"
          placeholder="to"
          disabled={!from}
          minDate={from ?? undefined}
          value={to}
          label="to"
          onChange={(date) => {
            if (!onChange) return;
            if (date === null) onChange({ from: null, to: null });
            onChange({ to: date });
          }}
          className="h-[41.6px] bg-white dark:bg-primary-950"
          style={{
            width: "fit-content",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </div>
      <div className="flex-col sm:hidden">
        <Datepicker
          name="from"
          icon={undefined}
          value={from}
          onChange={(date) => {
            if (!onChange) return;
            if (date === null) onChange({ from: null, to: null });
            onChange({ from: date });
          }}
          placeholder="from"
          label="from"
          className="h-[41.6px] bg-white dark:bg-primary-950"
          style={{
            width: "fit-content",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Datepicker
          name="to"
          icon={undefined}
          placeholder="to"
          disabled={!from}
          minDate={from ?? undefined}
          value={to}
          label="to"
          onChange={(date) => {
            if (!onChange) return;
            if (date === null) onChange({ from: null, to: null });
            onChange({ to: date });
          }}
          className="h-[41.6px] bg-white dark:bg-primary-950"
          style={{
            width: "fit-content",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </div>
    </div>
  );
}
