import { Dropdown, Modal, Pagination, Select } from "flowbite-react";
import moment from "moment";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { getRequestStateName, RequestState } from "../../types/request-status";
import { useState } from "react";
import { ImAttachment } from "react-icons/im";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { data, Link, useNavigate } from "react-router-dom";
import { getFileLink } from "../../utils/file";
import DateFilter from "../../components/filters/date-filter";
import { useDebouncedCallback } from "use-debounce";
import { Request } from "../../types/request";
import { DEFAULT_PULL } from "../../constants/api";
import Header from "../../components/header";
import { FilterBar } from "../../components/filters/filterbar";
import { SquareSkeleton } from "../../components/skeleton";
import Filter, { RequestsQuery } from "./components/Filter";

export function Requests() {

  const [query, setQuery] = useState<RequestsQuery>({})
  const [page, setPage] = useState<number>(1);
  const [desc, setdesc] = useState<string | undefined>("");
  const [show, setshow] = useState<boolean>(false);



  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.user?._id);
  const { data: requests, isLoading } = useQuery({
    queryKey: ["requests", page, query],
    queryFn: () =>
      apiCaller<{ length: number; requests: Request[] }>({
        method: "GET",
        url: `/owners/${userId}/requests`,
        params: {
          page,
          size: PAGINATION_SIZE,
          ...Object.fromEntries(
            Object.entries(query).filter(([_, value]) => value !== null)
          ),
        },
      }),
    placeholderData: keepPreviousData,
    ...DEFAULT_PULL,
  });
  const queryClient = useQueryClient();
  const changeRequestState = useMutation({
    mutationFn: (data: { requestId: string; state: RequestState }) =>
      apiCaller<void>({
        method: "POST",
        url: `/owners/${userId}/requests/${data.requestId}/state`,
        body: JSON.stringify({ state: data.state }),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    },
  });

  return (
    <div className="w-full max-md:px-4 h-full flex flex-col gap-4 max-sm:pb-2 sm:py-4">
      <Header title="requests" />
      <div className="sm:max-h-[calc(100vh_-_175px)] max-sm:max-h-[calc(100vh_-_187px)] overflow-auto w-full h-full flex flex-col gap-2">

        <Filter
          onSearch={(search) => setQuery(prev => ({ ...prev, name: search }))}
          onFilter={(query) => setQuery(query)}
        />

        <div className="flex flex-col justify-between h-full">
          <div className="overflow-auto relative border-x border-b rounded-lg border-neutral-200 dark:border-gray-700 sm:min-h-[calc(100vh_-_270px)] sm:max-h-[calc(100vh_-_270px)] max-sm:max-h-[calc(100vh_-_285px)] max-sm:min-h-[calc(100vh_-_285px)]">
            <table className="table-container">
              <thead className="table-head">
                <th className="rounded-l-lg table-head-cell">service</th>
                <th className="table-head-cell">patient</th>
                <th className="table-head-cell">Date</th>
                <th className="table-head-cell">Status</th>
                <th className="table-head-cell">Description</th>
                <th className="rounded-r-lg table-head-cell">
                  <span className="sr-only">Edit</span>
                </th>
              </thead>
              <tbody className="divide-y rounded-lg w-full h-full last:rounded-b-lg">
                {isLoading ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                    return (
                      <tr
                        key={item}
                        className="max-h-max bg-white dark:bg-primary-950 hover:bg-neutral-100 cursor-pointer border-0 shadow-sm"
                      >
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                        <td className="h-[50px] min-h-[50px] text-xs sm:text-sm p-2">
                          <SquareSkeleton className="w-full h-full max-h-10" />
                        </td>
                      </tr>
                    );
                  })
                ) : requests?.requests ? (
                  requests?.requests.map((request, indx) => {
                    return (
                      <tr
                        onClick={() =>
                          navigate(`/requests/${request._id}/chat`)
                        }
                        key={request._id}
                        className={`cursor-pointer hover:bg-neutral-100 dark:hover:bg-primary-dark-50 border-0 shadow-sm `}
                      >
                        <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-neutral-900 dark:text-white">
                          {request.service}
                        </td>
                        <td className="text-sm max-sm:text-xs p-2 text-neutral-900 dark:text-white">
                          {request.patient.userName}
                        </td>
                        <td className="text-sm max-sm:text-xs p-2 text-nowrap text-neutral-900 dark:text-white">
                          {moment(request.createdAt).format(
                            "DD MMM YYYY, hh:mm"
                          )}
                        </td>
                        <td className="text-sm max-sm:text-xs p-2 text-neutral-900 dark:text-white hover:text-primary-700"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Dropdown
                            label={getRequestStateName(request.state)}
                            inline
                            arrowIcon={false}
                          >
                            {Object.values(RequestState).map((state) => (
                              <Dropdown.Item
                                key={state}
                                onClick={() => {
                                  changeRequestState.mutate({
                                    requestId: request._id,
                                    state: state,
                                  });
                                }}
                                className={`${state === RequestState.IDLE && "text-primary-700"}`}
                              >
                                {getRequestStateName(state)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        </td>
                        <td className="w-1/3 px-1">
                          {request.desc ? (
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                setdesc(request.desc);
                                setshow(true);
                              }}
                              className="truncate cursor-pointer text-neutral-900 dark:text-white max-sm:line-clamp-2 sm:line-clamp-2 text-sm max-sm:text-xs text-wrap"
                            >
                              {request.desc}
                            </p>
                          ) : (
                            "_"
                          )}
                        </td>
                        <td className="text-sm flex items-center gap-4 max-sm:text-xs p-2">
                          {request.state === RequestState.IDLE &&
                            <div className="px-2 py-1 text-xs text-black rounded-lg bg-[#90eec4]">new</div>
                          }
                          <Link
                            to={`/requests/${request._id}/chat`}
                            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          >
                            <div className="w-fit relative">
                              <HiChatBubbleLeftRight size={20} />
                              {request.patientNewMessage ? (
                                <p className="absolute -top-2 -right-2 bg-neutral-700 text-white rounded-full flex items-center justify-center text-[10px] w-4 h-4">
                                  {request.patientNewMessage}
                                </p>
                              ) : null}
                            </div>
                          </Link>
                          {request?.file?.name ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <a
                                download
                                href={getFileLink(request?.file?.url)}
                                className="font-medium hover:scale-105 duration-100 ease-linear transition-all text-cyan-600 hover:underline dark:text-cyan-500"
                              >
                                <ImAttachment size={20} />
                              </a>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <p className="empty-msg w-full">no requests found!!!</p>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={page}
            className="text-xs sm:text-sm mt-2"
            previousLabel="prev"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
            onPageChange={setPage}
          />
        </div>
      </div>
      <Modal position="center" show={show} onClose={() => setshow(false)}>
        <Modal.Header className="modal-header">
          Request Description
        </Modal.Header>
        <Modal.Body>
          <p className="text-sm max-sm:text-xs">{desc}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
