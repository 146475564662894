import { Select } from "flowbite-react";
import DateFilter from "../../../components/filters/date-filter";
import { FilterBar } from "../../../components/filters/filterbar";
import { useEffect, useState } from "react";
import { PAGINATION_SIZE } from "../../../constants/pagination";
import { useDebouncedCallback } from "use-debounce";


type FollowupsTableFilterProps = {
    onFilter: (filter: gapi.client.calendar.EventsListParameters) => void
}

const convertQueryToCalendarFilter = (query: {
    name?: string;
    expand: boolean;
    from?: Date | null;
    to?: Date | null;
}) => {
    const filter: gapi.client.calendar.EventsListParameters = {
        calendarId: '',
        singleEvents: query.expand,
        maxResults: PAGINATION_SIZE,
        //@ts-ignore
        sharedExtendedProperty: ['type=followup']
    };
    if (query.from) {
        filter['timeMin'] = query.from.toISOString();
    }
    if (query.to) {
        filter["timeMax"] = query.to.toISOString();
    }
    if (query.name) {
        //@ts-ignore
        filter["sharedExtendedProperty"] = [...filter["sharedExtendedProperty"], `name=${query.name}`];
    }
    return filter
}
export default function FollowupsTableFilter(props: FollowupsTableFilterProps) {
    const [query, setQuery] = useState<{
        name?: string;
        expand: boolean;
        from?: Date | null;
        to?: Date | null;
    }>({
        from: null,
        to: null,
        expand: false,
    });

    const onSearch = useDebouncedCallback((e: any) => {
        const tmp = { ...query };
        tmp['name'] = e.target.value;
        setQuery(tmp);
        props.onFilter(convertQueryToCalendarFilter(tmp))

    }, 300);

    return (
        <FilterBar
            onChange={onSearch}
            onCancle={() => {
                const tmp = {
                    from: null,
                    to: null,
                    expand: false,
                }
                setQuery(tmp)
                props.onFilter(convertQueryToCalendarFilter(tmp))
            }}
            onSubmit={(e) => {
                e.preventDefault();
                props.onFilter(convertQueryToCalendarFilter(query))
            }}
        >
            <div className="flex gap-2 w-full flex-wrap">
                <DateFilter
                    from={query?.from}
                    to={query?.to}
                    onChange={(e) => setQuery((prev) => ({ ...prev, ...e }))}
                />
                <div className="flex flex-col w-1/2 max-w-xs">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Expand
                    </label>
                    <Select
                        id="countries"
                        className="bg-white rounded-lg w-full"
                        onChange={(e) => {
                            const id = e.target.options[e.target.selectedIndex].dataset.id;
                            setQuery((prev) => ({ ...prev, expand: id === "true" }));
                        }}
                    >
                        {[
                            { name: "Single", id: false },
                            { name: "Expand", id: true },
                        ].map((item) => {
                            return (
                                <option key={item.name} data-id={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        </FilterBar>

    )
}