import Chart from "./components/charts";
import RecentRequests from "./requests";
import RecentFollowUps from "./followups";
import RecentTickets from "./tickets";
import { useAppStore } from "../../stores/app-store";
import { useShallow } from "zustand/shallow";
import Header from "../../components/header";

export default function Dashboard() {
  const requestsStatus = useAppStore(
    useShallow((state) => state.requestsStatus)
  );

  return (
    <div
      className=" w-full h-full my-2 max-md:px-4 gap-4 flex flex-col">
      <Header title="dashboard" />
      <div className="sm:max-h-[calc(100vh_-_155px)] max-sm:max-h-[calc(100vh_-_183px)] h-full w-full flex flex-col gap-4 overflow-auto">
        <div className="w-full flex min-h-[300px] gap-4 h-full max-sm:flex-col">

          <RecentFollowUps />
          <Chart />
        </div>
        <div className="w-full h-full flex gap-4 min-h-[300px] max-lg:flex-col">
          {/* 
            FIXME fix fetching indicator for RecentTickets and RecentRequests
          */}
          <RecentTickets
            total={requestsStatus.item?.totalTickets ?? 0}
            tickets={requestsStatus.item?.tickets ?? 0}
            isLoading={requestsStatus.isLoading}
            isFetching={requestsStatus.isFetching}
          />
          <RecentRequests
            total={requestsStatus.item?.totalNewRequest || 0}
            tickets={requestsStatus.item?.newRequests || []}
            isLoading={requestsStatus.isLoading}
            isFetching={requestsStatus.isFetching}
          />
        </div>
      </div>
    </div>
  );
}
